import { environment } from '../../../environments/environment';

export interface FEATURE_TOGGLE {
  id: string;
}

let apply: <T extends FEATURE_TOGGLE>(features: T[]) => T[] = (features) => {
  if (!environment.disabledFeatures) {
    apply = <T extends FEATURE_TOGGLE>(features: T[]): T[] => features;
  } else {
    apply = <T extends FEATURE_TOGGLE>(features: T[]): T[] =>
      features.filter(
        (feature) =>
          !(environment.disabledFeatures as Record<string, boolean>)[feature.id]
      );
  }

  return apply(features);
};

export const applyFeatureToggle = apply;
