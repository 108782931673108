import { Color } from '../../color.model';

export const gold: Color = {
  light: {
    1: '#fdfdfc',
    2: '#faf9f2',
    3: '#f2f0e7',
    4: '#eae6db',
    5: '#e1dccf',
    6: '#d8d0bf',
    7: '#cbc0aa',
    8: '#b9a88d',
    9: '#978365',
    10: '#8c7a5e',
    11: '#71624b',
    12: '#3b352b',
  },
  dark: {
    1: '#121211',
    2: '#1b1a17',
    3: '#24231f',
    4: '#2d2b26',
    5: '#38352e',
    6: '#444039',
    7: '#544f46',
    8: '#696256',
    9: '#978365',
    10: '#a39073',
    11: '#cbb99f',
    12: '#e8e2d9',
  },
};
