import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  map,
  Observable,
  of,
  OperatorFunction,
  pipe,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { ClientStorageService } from '../modules/launchpad/client-storage.service';
import { canRenew, requiresRefresh } from './access-token.util';
import { ApiModel, ClientModel } from './auth.model';

const { glueBaseApi } = environment;

const toMilliseconds = (timeInSeconds: number) => timeInSeconds * 1000;

@Injectable({ providedIn: 'root' })
export class AccessTokenService {
  constructor(
    private http: HttpClient,
    private storageService: ClientStorageService<ClientModel.AccessToken>
  ) {}

  /**
   * @param key the logical key that is used to sync the token with local storage
   */
  get(key: string): Observable<ClientModel.AccessToken | null> {
    return this.storageService.get(key + '-access-token').pipe(
      switchMap((token: ClientModel.AccessToken | null) => {
        if (token && requiresRefresh(token)) {
          if (canRenew(token)) {
            return this.renew(token as Required<ClientModel.AccessToken>, key);
          } else {
            this.remove(key);
            return of(null);
          }
        }

        return of(token);
      })
    );
  }

  /**
   * @param key the logical key of the token that we want to remove
   */
  remove(key: string): void {
    this.storageService.remove(key + '-access-token');
  }

  removeAll(): void {
    this.storageService.clear();
  }

  /**
   * @param params contains the exchange parameters such as type, session code and pkce code.
   */
  load(
    params: ClientModel.TokenExchangeParams
  ): Observable<ClientModel.AccessToken> {
    const { code, redirectUri, codeVerifier: code_verifier } = params;
    const requestBody = {
      data: {
        type: 'token',
        attributes: {
          grantType: 'access_token',
          code,
          redirectUri,
          ...(code_verifier && { code_verifier }),
        },
      },
    };
    return this.http
      .post<ApiModel.AccessTokenResponse>(
        `${glueBaseApi}/auth-servers/${params.key}/token`,
        requestBody
      )
      .pipe(this.handleTokenResponse(params.key));
  }

  public renew(
    token: Required<ClientModel.AccessToken>,
    key: string
  ): Observable<ApiModel.AccessToken> {
    return this.http
      .post<ApiModel.AccessTokenResponse>(
        `${glueBaseApi}/auth-servers/${key}/token`,
        {
          data: {
            type: 'token',
            attributes: {
              grantType: 'refresh_token',
              refreshToken: token.refreshToken,
            },
          },
        }
      )
      .pipe(this.handleTokenResponse(key));
  }

  private handleTokenResponse(
    key: string
  ): OperatorFunction<ApiModel.AccessTokenResponse, ApiModel.AccessToken> {
    return pipe(
      tap((response: ApiModel.AccessTokenResponse) => {
        this.storageService.set(
          `${key}-access-token`,
          this.convertResponse(response?.data?.attributes)
        );
      }),
      catchError((e: HttpErrorResponse) => {
        this.remove(key);
        return throwError(() => e);
      }),
      map((response) => this.convertResponse(response?.data?.attributes))
    );
  }

  private convertResponse(
    source: ApiModel.AccessToken
  ): ClientModel.AccessToken {
    const target: ClientModel.AccessToken = {
      accessToken: source.accessToken,
    };
    if (source.tokenType) {
      target.tokenType = source.tokenType;
    }

    if (source.expiresAt) {
      target.expiresAt = toMilliseconds(source.expiresAt);
    }

    if (source.idToken) {
      target.idToken = source.idToken;
    }

    if (source.refreshToken) {
      target.refreshToken = source.refreshToken;
      if (source.refreshTokenExpiresAt) {
        target.refreshTokenExpiresAt = toMilliseconds(
          source.refreshTokenExpiresAt
        );
      }
    }

    return target;
  }
}
