import { ClientModel } from '../../utils/json-api';

export interface AppFeatureModule extends ClientModel.AppFeature {
  module?: any;
  required?: boolean;
  position?: FEATURE_POSITION;
}

export enum FEATURES {
  general = 'general',
  code_repository = 'Code Repository provider',
  commerce_provider = 'Commerce provider',
  hosting = 'Hosting provider',
  delete = 'Remove application',
}

export enum FEATURE_POSITION {
  top = 'top',
  bottom = 'bottom',
}

export type APP_FEATURES_MAP = Partial<
  Record<FEATURES, Pick<AppFeatureModule, 'module'>>
>;
