import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NO_ACCESS_ROUTE } from './constants';
import { RequireLoggedInGuard } from './guards/require-logged-in.guard';
import { RequireLoggedOutGuard } from './guards/require-logged-out.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./app.module').then((m) => m.AppModule),
    canActivateChild: [RequireLoggedInGuard],
  },
  {
    path: NO_ACCESS_ROUTE,
    loadChildren: () =>
      import('./auth/login/login.module').then((m) => m.LoginModule),
    canActivate: [RequireLoggedOutGuard],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RootRoutingModule {}
