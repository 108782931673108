import {
  HttpErrorResponse,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

export type FesNotificationErrorConfig = {
  [key: string]: (
    error: HttpErrorResponse,
    request: HttpRequest<unknown>
  ) => FesNotification;
};

export type FesNotificationSuccessConfig = {
  [key: string]: (
    response: HttpResponse<unknown>,
    request: HttpRequest<unknown>
  ) => FesNotification;
};

interface FesNotificationModel {
  title: string;
  description: string;
}

export class FesNotification implements Partial<FesNotificationModel> {
  title?: string;
  description?: string;

  constructor(title?: string, description?: string) {
    this.title = title;
    this.description = description;
  }
}
