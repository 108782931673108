import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { AccessTokenService } from '../auth/access-token.service';
import { AUTH_KEY } from '../auth/auth.config';
import { NO_ACCESS_RETURN_URL_PARAM, NO_ACCESS_ROUTE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class RequireLoggedInGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private accessTokenService: AccessTokenService
  ) {}

  canActivateChild(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.accessTokenService.get(AUTH_KEY).pipe(
      map((accessToken) => !!accessToken),
      tap((isLogged) => {
        if (!isLogged) {
          this.router.navigate([NO_ACCESS_ROUTE], {
            queryParams: { [NO_ACCESS_RETURN_URL_PARAM]: state.url },
          });
        }
      })
    );
  }
}
