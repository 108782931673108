import { Color } from '../../color.model';

export const violet: Color = {
  light: {
    1: 'hsl(255, 65.0%, 99.4%)',
    2: 'hsl(252, 100%, 99.0%)',
    3: 'hsl(252, 96.9%, 97.4%)',
    4: 'hsl(252, 91.5%, 95.5%)',
    5: 'hsl(252, 85.1%, 93.0%)',
    6: 'hsl(252, 77.8%, 89.4%)',
    7: 'hsl(252, 71.0%, 83.7%)',
    8: 'hsl(252, 68.6%, 76.3%)',
    9: 'hsl(252, 56.0%, 57.5%)',
    10: 'hsl(251, 48.1%, 53.5%)',
    11: 'hsl(250, 43.0%, 48.0%)',
    12: 'hsl(254, 60.0%, 18.5%)',
  },
  dark: {
    1: 'hsl(250, 20.0%, 10.2%)',
    2: 'hsl(255, 30.3%, 12.9%)',
    3: 'hsl(253, 37.0%, 18.4%)',
    4: 'hsl(252, 40.1%, 22.5%)',
    5: 'hsl(252, 42.2%, 26.2%)',
    6: 'hsl(251, 44.3%, 31.1%)',
    7: 'hsl(250, 46.8%, 38.9%)',
    8: 'hsl(250, 51.8%, 51.2%)',
    9: 'hsl(252, 56.0%, 57.5%)',
    10: 'hsl(251, 63.2%, 63.2%)',
    11: 'hsl(250, 95.0%, 76.8%)',
    12: 'hsl(252, 87.0%, 96.4%)',
  },
};
