import { Color } from '../../color.model';

export const olive: Color = {
  light: {
    1: 'hsl(110, 20.0%, 99.0%)',
    2: 'hsl(120, 16.7%, 97.6%)',
    3: 'hsl(119, 10.1%, 95.2%)',
    4: 'hsl(118, 8.1%, 93.0%)',
    5: 'hsl(117, 7.1%, 90.8%)',
    6: 'hsl(115, 6.4%, 88.5%)',
    7: 'hsl(114, 5.9%, 85.4%)',
    8: 'hsl(110, 5.2%, 77.3%)',
    9: 'hsl(110, 3.5%, 55.5%)',
    10: 'hsl(111, 2.8%, 51.7%)',
    11: 'hsl(110, 3.0%, 43.0%)',
    12: 'hsl(110, 25.0%, 9.5%)',
  },
  dark: {
    1: 'hsl(110, 5.0%, 8.6%)',
    2: 'hsl(105, 7.4%, 10.6%)',
    3: 'hsl(106, 6.4%, 13.1%)',
    4: 'hsl(106, 5.8%, 15.3%)',
    5: 'hsl(107, 5.3%, 17.4%)',
    6: 'hsl(107, 4.9%, 19.9%)',
    7: 'hsl(108, 4.4%, 23.6%)',
    8: 'hsl(110, 3.8%, 30.6%)',
    9: 'hsl(110, 6.0%, 42.5%)',
    10: 'hsl(111, 4.8%, 48.2%)',
    11: 'hsl(110, 5.0%, 61.8%)',
    12: 'hsl(110, 6.0%, 93.0%)',
  },
};
