import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
} from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  LoaderState,
  LoaderStates,
  LoaderStateStatus,
} from '../../../../utils/models/loading.model';
import { CodeRepositoryFormService } from './code-repository-form.service';
import { Loader } from './loader';
import {
  ClientModel,
  CodeRepositoryFormFields,
  CodeRepositoryFormFieldsName,
} from './model';
import Status = LoaderStates.Status;

const defaultSuccess = <T>(value: T): LoaderStates.NotEmptySuccess<T> => {
  return {
    status: LoaderStateStatus.SUCCESS,
    value,
  };
};

const checkSelectedValueInSync =
  <T, V>(
    control: AbstractControl,
    predicate: (current: T, target: V) => boolean
  ) =>
  (source: Observable<LoaderState<T[]>>): Observable<LoaderState<T[]>> =>
    source.pipe(
      tap((streamValue) => {
        if (streamValue.status !== Status.SUCCESS) {
          return;
        }

        const isValueInTheList = (
          streamValue as LoaderStates.NotEmptySuccess<T[]>
        ).value.some((v) => predicate(v, control.value));

        if (!isValueInTheList) {
          control.setValue('');
        }
      })
    );

@Injectable()
export class CodeRepositoryFacade {
  constructor(
    private loader: Loader,
    private formService: CodeRepositoryFormService
  ) {}

  private getControl(id: keyof CodeRepositoryFormFields): AbstractControl {
    return this.formService.controls[id] as AbstractControl;
  }

  private getControlValue(id: keyof CodeRepositoryFormFields): string {
    return this.getControl(id)?.value;
  }

  getOrganizations(): Observable<LoaderState<ClientModel.Organization[]>> {
    const organizationIdControl = this.getControl(
      CodeRepositoryFormFieldsName.ORGANIZATION_ID
    );

    return this.loader.loadOrganizations().pipe(
      shareReplay(1),
      checkSelectedValueInSync(
        organizationIdControl,
        (current, target) => current.id === target
      )
    );
  }

  getRepositories(): Observable<LoaderState<ClientModel.Repository[]>> {
    const organizationId = this.getControlValue(
      CodeRepositoryFormFieldsName.ORGANIZATION_ID
    );

    const repositoryIdControl = this.getControl(
      CodeRepositoryFormFieldsName.REPOSITORY_ID
    );

    if (organizationId) {
      return this.loader
        .loadRepositories({
          organizationId,
        })
        .pipe(
          shareReplay(1),
          checkSelectedValueInSync(
            repositoryIdControl,
            (current, target) => current.id === target
          )
        );
    }

    return of(defaultSuccess<ClientModel.Repository[]>([]));
  }

  getBranches(): Observable<LoaderState<ClientModel.Branch[]>> {
    const repositoryId = this.getControlValue(
      CodeRepositoryFormFieldsName.REPOSITORY_ID
    );

    const branchNameControl = this.getControl(
      CodeRepositoryFormFieldsName.BRANCH
    );

    if (repositoryId) {
      return this.loader
        .loadBranches({
          organizationName: this.getControlValue(
            CodeRepositoryFormFieldsName.ORGANIZATION_NAME
          ),
          organizationId: this.getControlValue(
            CodeRepositoryFormFieldsName.ORGANIZATION_ID
          ),
          repositoryId,
        })
        .pipe(
          shareReplay(1),
          checkSelectedValueInSync(
            branchNameControl,
            (current, target) => current === target
          )
        );
    }

    return of(defaultSuccess<ClientModel.Branch[]>([]));
  }

  isFieldValid(id: keyof CodeRepositoryFormFields): Observable<boolean> {
    const control = this.getControl(id);

    return control.statusChanges.pipe(
      distinctUntilChanged(),
      map((value) => value === 'VALID'),
      startWith(control.valid)
    );
  }

  getValueOf<T>(id: keyof CodeRepositoryFormFields): Observable<T> {
    const control = this.getControl(id);

    return control.valueChanges.pipe(
      distinctUntilChanged(),
      startWith(control.value)
    );
  }
}
