import { LAUNCHPAD_ROOT_PATH } from '../../../../../constants';
import { CodeRepositoryConfig } from '../model';

export const GITLAB_CONFIG: Omit<CodeRepositoryConfig, 'installUrl'> = {
  baseApi: 'https://gitlab.com/api/v4',
  authorizeUrl: 'https://gitlab.com/oauth/authorize',
  acceptHeader: `application/vnd.gitlab.v3+json`,
  windowOptions: 'height=800,width=1200,location=0',
  target: 'gitlabInstallation',
  callback: `${LAUNCHPAD_ROOT_PATH}/gitlab/callback`,
  key: 'gitlab',
};

export const GITLAB_LABELS = {
  authorize: 'Authorize GitLab',
  authorizeHint:
    'To change the configuration, you need to be authorized with GitLab.',
};
