import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
} from 'rxjs';
import { ClientModel } from '../utils/json-api';
import { FEATURES } from './app-features/app.model';

export type Values = Record<string, Record<string, unknown>>;
@Injectable()
export class AppFormStateService {
  private defaults?: ClientModel.App;
  form: UntypedFormGroup = new UntypedFormGroup({
    features: new UntypedFormGroup({}),
  });
  disabled$ = new BehaviorSubject<boolean>(false);

  getFeaturesControl(): UntypedFormGroup {
    return this.form.get('features') as UntypedFormGroup;
  }

  getValue<T>(): T {
    return this.form.getRawValue();
  }

  setValue(value: { features: Values }): void {
    this.form.patchValue(value);
  }

  register(
    feature: FEATURES,
    resolveForm: (defaults?: ClientModel.App) => UntypedFormGroup
  ): void {
    this.getFeaturesControl().setControl(feature, resolveForm(this.defaults));
    this.disabled$.value ? this.disableForm() : this.enableForm();
  }

  remove(feature: FEATURES): void {
    this.getFeaturesControl().removeControl(feature);
  }

  subscribeToValueOf<T>(feature: FEATURES): Observable<T> {
    return this.form.valueChanges.pipe(
      distinctUntilChanged(
        (prev, next) =>
          JSON.stringify(prev['features'][feature]) ===
          JSON.stringify(next['features'][feature])
      ),
      startWith(null),
      map(() => {
        return this.form.get('features')?.get(feature)?.value || null;
      })
    );
  }

  isValid(): boolean {
    return this.form.valid;
  }

  isFormEqualTo(candidate: { features: Values }): boolean {
    return JSON.stringify(candidate) === JSON.stringify(this.getValue());
  }

  setDefaultValues(value: ClientModel.App): void {
    this.defaults = value;
  }

  getDefaultValues(): ClientModel.App | undefined {
    return this.defaults;
  }

  hasDefaults(): boolean {
    return !!this.defaults;
  }

  disable(): void {
    this.disableForm();
    this.disabled$.next(true);
  }

  enable(): void {
    this.enableForm();
    this.disabled$.next(false);
  }

  private forEachFeatureControl(predicate: (control: AbstractControl) => void) {
    Object.values(this.getFeaturesControl().controls).forEach((control) => {
      predicate(control);
    });
  }

  private enableForm(): void {
    this.forEachFeatureControl((control) => {
      control.enable({ emitEvent: false, onlySelf: true });
    });
  }

  private disableForm(): void {
    this.forEachFeatureControl((control) => {
      control.disable({ emitEvent: false, onlySelf: true });
    });
  }
}
