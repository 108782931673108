import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../../../services/http/http.service';
import { LoaderState } from '../../../../../utils/models/loading.model';
import { CodeRepositoryFormValues, Loader } from '../loader';
import { ClientModel } from '../model';
import { GITLAB_CONFIG } from './gitlab.config';
import { GitLabApiModel } from './gitlab.model';

@Injectable()
export class GitlabLoaderService implements Loader {
  constructor(private httpService: HttpService) {}

  loadOrganizations(): Observable<LoaderState<ClientModel.Organization[]>> {
    return this.httpService.get<
      GitLabApiModel.GitlabGroupsResponse,
      ClientModel.Organization[]
    >(
      `${GITLAB_CONFIG.baseApi}/groups`,
      (response: GitLabApiModel.GitlabGroupsResponse) =>
        response.map(
          (organization: GitLabApiModel.GitlabGroup) =>
            <ClientModel.Organization>{
              id: organization.id.toString(),
              name: organization.name,
            }
        ) ?? []
    );
  }

  loadRepositories({
    organizationId,
  }: CodeRepositoryFormValues): Observable<
    LoaderState<ClientModel.Repository[]>
  > {
    return this.httpService.get<
      GitLabApiModel.GitLabProjectsResponse,
      ClientModel.Repository[]
    >(
      `${GITLAB_CONFIG.baseApi}/groups/${organizationId}/projects?simple=true`,
      (response: GitLabApiModel.GitLabProjectsResponse) =>
        response?.map(
          (repo: GitLabApiModel.GitlabProject) =>
            ({
              name: repo.name,
              id: repo.id.toString(),
              path_with_namespace: repo.path_with_namespace,
            } ?? [])
        )
    );
  }

  loadBranches({
    repositoryId,
  }: CodeRepositoryFormValues): Observable<LoaderState<ClientModel.Branch[]>> {
    return this.httpService.get<
      GitLabApiModel.GitLabBranchResponse[],
      ClientModel.Branch[]
    >(
      `${GITLAB_CONFIG.baseApi}/projects/${repositoryId}/repository/branches`,
      (response: GitLabApiModel.GitLabBranchResponse[]) =>
        response.map(
          (branch: GitLabApiModel.GitLabBranchResponse) => branch.name
        ) ?? []
    );
  }
}
