import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { LoaderState, LoaderStates } from '../../utils/models/loading.model';
import Status = LoaderStates.Status;

const initialState: LoaderStates.Loading = {
  status: Status.LOADING,
};

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  get<T, M>(
    url: string,
    normalizer: (response: T) => M
  ): Observable<LoaderState<M>> {
    return this.http.get<T>(url).pipe(
      map((response) => this.handleSuccess<T, M>(response, normalizer)),
      catchError(() => of(this.handleError())),
      startWith(initialState)
    );
  }

  post<T, M, B>(
    url: string,
    body: B,
    normalizer: (response: T) => M
  ): Observable<LoaderState<M>> {
    return this.http.post<T>(url, body).pipe(
      map((response) => this.handleSuccess<T, M>(response, normalizer)),
      catchError(() => of(this.handleError())),
      startWith(initialState)
    );
  }

  patch<T, M, B>(
    url: string,
    body: B,
    normalizer: (response: T) => M
  ): Observable<LoaderState<M>> {
    return this.http.patch<T>(url, body).pipe(
      map((response) => this.handleSuccess<T, M>(response, normalizer)),
      catchError(() => of(this.handleError())),
      startWith(initialState)
    );
  }

  delete<T, M>(
    url: string,
    normalizer: (response: T) => M
  ): Observable<LoaderState<M>> {
    return this.http.delete<T>(url).pipe(
      map((response: T) => this.handleSuccess<T, M>(response, normalizer)),
      catchError(() => of(this.handleError())),
      startWith(initialState)
    );
  }

  private handleSuccess<T, M>(
    response: T,
    normalizer: (response: T) => M
  ): LoaderStates.Success<M> {
    return {
      status: LoaderStates.Status.SUCCESS,
      value: normalizer(response),
    } as LoaderStates.Success<M>;
  }

  private handleError(): LoaderStates.Error {
    return {
      status: Status.ERROR,
    };
  }
}
