import { HttpRequest } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

export interface RequestIdentifierStrategy {
  canHandle: (request: HttpRequest<unknown>) => boolean;
  getIdentifier: (request: HttpRequest<unknown>) => string;
}

export const REQUEST_IDENTIFIER_STRATEGIES_TOKEN = new InjectionToken<
  RequestIdentifierStrategy[]
>('Request identifier strategies');
