import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';

@Directive({ selector: '[appStopPropagation]' })
export class StopPropagationDirective implements OnDestroy {
  @Input('appStopPropagation') set stopPropagationEvents(value: string[]) {
    if (value) {
      this.clearDisposeList();
      this.setDisposeList(value);
    }
  }
  private disposeList: Array<() => void> = [];

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnDestroy(): void {
    this.clearDisposeList();
  }

  private setDisposeList(disposeList: string[]): void {
    this.disposeList = disposeList.map((event) => {
      return this.renderer.listen(
        this.elementRef.nativeElement,
        event,
        (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
        }
      );
    });
  }

  private clearDisposeList(): void {
    this.disposeList.forEach((dispose) => {
      dispose();
    });
  }
}
