import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { AccessTokenService } from '../../../../../auth/access-token.service';
import { AppFormStateService } from '../../../app/app-form/app-form-state.service';
import { DefaultButtonType } from '../../../app/utils/default-button-type';
import { DefaultSize } from '../../../app/utils/default-size';
import { ClientModel } from '../../../app/utils/json-api';
import { CodeRepositoryFacade } from '../code-repository-facade.service';
import { CodeRepositoryFormService } from '../code-repository-form.service';
import { Loader } from '../loader';
import { FEATURE_PROVIDERS } from '../model';
import { ProvidersAuthService } from '../providers-auth.service';
import { BitbucketLoaderService } from './bitbucket-loader.service';
import { BITBUCKET_CONFIG, BITBUCKET_LABELS } from './bitbucket.config';

@Component({
  selector: 'pad-bitbucket',
  templateUrl: './bitbucket.component.html',
  styleUrls: ['../styles/provider.components.scss'],
  providers: [
    {
      provide: 'feature',
      useFactory: (injector: Injector) =>
        injector.get('featureData')[FEATURE_PROVIDERS.BITBUCKET],
      deps: [Injector],
    },
    CodeRepositoryFacade,
    {
      provide: Loader,
      useClass: BitbucketLoaderService,
    },
  ],
})
export class BitbucketComponent implements OnInit {
  ButtonSize = DefaultSize;

  ButtonType = DefaultButtonType;

  configuration = BITBUCKET_CONFIG;
  labels = BITBUCKET_LABELS;

  viewState$?: Observable<{ isDisabled: boolean; hasToken: boolean }>;

  constructor(
    private accessTokenService: AccessTokenService,
    private appStateService: AppFormStateService,
    private formService: CodeRepositoryFormService,
    @Inject('feature')
    private featureFields: ClientModel.AppFeatureFields,
    private providersAuthService: ProvidersAuthService
  ) {}

  ngOnInit(): void {
    this.formService.useFeature(this.featureFields);

    this.viewState$ = combineLatest([
      this.appStateService.disabled$,
      this.accessTokenService.get(BITBUCKET_CONFIG.key).pipe(map(Boolean)),
    ]).pipe(
      map(([isDisabled, hasToken]) => ({ isDisabled, hasToken })),
      tap(({ isDisabled, hasToken }) => {
        !isDisabled && hasToken
          ? this.formService.enable()
          : this.formService.disable();
      })
    );
  }

  authorize(): void {
    this.providersAuthService.authorize(
      this.configuration,
      environment.bitbucket.clientId
    );
  }
}
