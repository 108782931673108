<ng-container *ngIf="viewState$ | async as viewState">
  <ng-container *ngIf="!viewState.isDisabled && !viewState.hasToken">
    <oryx-button
      [type]="ButtonType.Outline"
      [size]="ButtonSize.Lg"
      (click)="authorize()"
    >
      <button slot="custom" data-testid="authorize-bitbucket">
        <img icon src="assets/icons/bitbucket.svg" alt="Bitbucket" />
        {{ labels.authorize }}
      </button>
    </oryx-button>

    <p class="not-authorised">
      {{ labels.authorizeHint }}
    </p>
  </ng-container>

  <div class="code-repository-inputs">
    <pad-organization></pad-organization>
    <pad-repository></pad-repository>
    <pad-branch></pad-branch>
  </div>
</ng-container>
