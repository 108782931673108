import { Color } from '../../color.model';

export const indigo: Color = {
  light: {
    1: 'hsl(225, 60.0%, 99.4%)',
    2: 'hsl(223, 100%, 98.6%)',
    3: 'hsl(223, 98.4%, 97.1%)',
    4: 'hsl(223, 92.9%, 95.0%)',
    5: 'hsl(224, 87.1%, 92.0%)',
    6: 'hsl(224, 81.9%, 87.8%)',
    7: 'hsl(225, 77.4%, 82.1%)',
    8: 'hsl(226, 75.4%, 74.5%)',
    9: 'hsl(226, 70.0%, 55.5%)',
    10: 'hsl(226, 58.6%, 51.3%)',
    11: 'hsl(226, 55.0%, 45.0%)',
    12: 'hsl(226, 62.0%, 17.0%)',
  },
  dark: {
    1: 'hsl(229, 24.0%, 10.0%)',
    2: 'hsl(230, 36.4%, 12.9%)',
    3: 'hsl(228, 43.3%, 17.5%)',
    4: 'hsl(227, 47.2%, 21.0%)',
    5: 'hsl(227, 50.0%, 24.1%)',
    6: 'hsl(226, 52.9%, 28.2%)',
    7: 'hsl(226, 56.0%, 34.5%)',
    8: 'hsl(226, 58.2%, 44.1%)',
    9: 'hsl(226, 70.0%, 55.5%)',
    10: 'hsl(227, 75.2%, 61.6%)',
    11: 'hsl(228, 100%, 75.9%)',
    12: 'hsl(226, 83.0%, 96.3%)',
  },
};
