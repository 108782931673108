import { Color } from '../../color.model';

export const purple: Color = {
  light: {
    1: 'hsl(280, 65.0%, 99.4%)',
    2: 'hsl(276, 100%, 99.0%)',
    3: 'hsl(276, 83.1%, 97.0%)',
    4: 'hsl(275, 76.4%, 94.7%)',
    5: 'hsl(275, 70.8%, 91.8%)',
    6: 'hsl(274, 65.4%, 87.8%)',
    7: 'hsl(273, 61.0%, 81.7%)',
    8: 'hsl(272, 60.0%, 73.5%)',
    9: 'hsl(272, 51.0%, 54.0%)',
    10: 'hsl(272, 46.8%, 50.3%)',
    11: 'hsl(272, 50.0%, 45.8%)',
    12: 'hsl(272, 66.0%, 16.0%)',
  },
  dark: {
    1: 'hsl(284, 20.0%, 9.6%)',
    2: 'hsl(283, 30.0%, 11.8%)',
    3: 'hsl(281, 37.5%, 16.5%)',
    4: 'hsl(280, 41.2%, 20.0%)',
    5: 'hsl(279, 43.8%, 23.3%)',
    6: 'hsl(277, 46.4%, 27.5%)',
    7: 'hsl(275, 49.3%, 34.6%)',
    8: 'hsl(272, 52.1%, 45.9%)',
    9: 'hsl(272, 51.0%, 54.0%)',
    10: 'hsl(273, 57.3%, 59.1%)',
    11: 'hsl(275, 80.0%, 71.0%)',
    12: 'hsl(279, 75.0%, 95.7%)',
  },
};
