import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestIdentifierStrategy } from './request-identifier.model';

const { atrs } = environment;

@Injectable()
export class AtrsRequestIdentifier implements RequestIdentifierStrategy {
  canHandle(request: HttpRequest<unknown>): boolean {
    return request.url.indexOf(atrs.baseUrl) > -1;
  }

  getIdentifier(request: HttpRequest<unknown>): string {
    const identifier = request.url.substring(
      request.url.indexOf(atrs.baseUrl) + atrs.baseUrl.length,
      request.url.indexOf('?') > 0 ? request.url.indexOf('?') : undefined
    );

    return `${request.method}${identifier}`;
  }
}
