import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable, take } from 'rxjs';
import { AccessTokenService } from '../../../../auth/access-token.service';
import { PROVIDERS_CONFIG } from './code-repository-providers.config';

@Injectable()
export class ProvidersAuthInterceptor implements HttpInterceptor {
  constructor(private accessTokenService: AccessTokenService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let paramReq: HttpRequest<unknown> = req.clone();

    const config = Object.values(PROVIDERS_CONFIG).find((provider) =>
      req.url.includes(provider.baseApi)
    );
    if (config) {
      return this.accessTokenService.get(config.key).pipe(
        take(1),
        mergeMap((token) => {
          if (token) {
            paramReq = req.clone({
              headers: req.headers
                .set(
                  'Authorization',
                  `${token.tokenType ?? 'bearer'} ${token.accessToken}`
                )
                .set('Accept', config.acceptHeader),
            });
          }

          return next.handle(paramReq);
        })
      );
    }

    return next.handle(paramReq);
  }
}
