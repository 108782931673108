import { UntypedFormControl } from '@angular/forms';

export module ClientModel {
  export interface Organization {
    id: string;
    name: string;
  }
  export interface Repository {
    name: string;
    id: string;
    path_with_namespace?: string;
  }

  export type Branch = string;
}

export enum FEATURE_PROVIDERS {
  GITHUB,
  GITLAB,
  BITBUCKET,
}

export type CodeRepositoryFormFields = Record<
  CodeRepositoryFormFieldsName,
  UntypedFormControl
>;

export enum CodeRepositoryFormFieldsName {
  ORGANIZATION_ID = 'organizationId',
  ORGANIZATION_NAME = 'organizationName',
  REPOSITORY_ID = 'repositoryId',
  REPOSITORY_NAME = 'repositoryName',
  REPOSITORY_PATH = 'repositoryPath',
  BRANCH = 'branch',
}

export interface CodeRepositoryConfig {
  baseApi: string;
  authorizeUrl: string;
  windowOptions: string;
  installUrl: string;
  target: string;
  callback: string;
  acceptHeader: string;
  key: string;
}
