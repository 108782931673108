import {
  HttpErrorResponse,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { appCreateSuccess } from '../modules/launchpad/app/app-create/app-create.notification';
import {
  appEditFetchError,
  appEditUpdateError,
  appEditUpdateSuccess,
} from '../modules/launchpad/app/app-edit/app-edit.notification';
import {
  appDeleteError,
  appDeleteSuccess,
} from '../modules/launchpad/features/app-delete/app-delete.notification';
import {
  commerceProviderStoresError,
  commerceProviderTenantsError,
} from '../modules/launchpad/features/commerce-provider/commerce-provider-notifications/commerce-provider.notification';
import {
  hostingEventError,
  hostingEventSuccess,
} from '../modules/launchpad/features/hosting/hosting-notifications/hosting-events.notification';
import {
  hostingProviderDeleteError,
  hostingProviderDeleteSuccess,
} from '../utils/models/hosting-model';
import {
  FesNotification,
  FesNotificationErrorConfig,
  FesNotificationSuccessConfig,
} from './fes-notification.model';

export const FES_NOTIFICATION_ERROR_MAP_TOKEN: FesNotificationErrorConfig = {
  'POST/apps': (error: HttpErrorResponse) => glueError(error),
  'GET/apps': (error: HttpErrorResponse) => glueError(error),
  'GET/apps/id': () => appEditFetchError(),
  'PATCH/apps/id': (error: HttpErrorResponse, request: HttpRequest<any>) =>
    appEditUpdateError(error, request),
  'DELETE/apps/id': () => appDeleteError(),
  'POST/apps/events': (error: HttpErrorResponse, request: HttpRequest<any>) =>
    hostingEventError(error, request),
  'GET/tenants': () => commerceProviderTenantsError(),
  'GET/stores': () => commerceProviderStoresError(),
  'POST/auth-servers/auth0/token': (error: HttpErrorResponse) =>
    glueError(error),
  'GET/hosting-providers': (error: HttpErrorResponse) => glueError(error),
  'POST/hosting-providers': (error: HttpErrorResponse) => glueError(error),
  'DELETE/hosting-providers/id': () => hostingProviderDeleteError(),
};

export const FES_NOTIFICATION_SUCCESS_MAP_TOKEN: FesNotificationSuccessConfig =
  {
    'POST/apps': (response: HttpResponse<any>) => appCreateSuccess(response),
    'PATCH/apps/id': () => appEditUpdateSuccess(),
    'DELETE/apps/id': () => appDeleteSuccess(),
    'POST/apps/events': (
      response: HttpResponse<any>,
      request: HttpRequest<any>
    ) => hostingEventSuccess(response, request),
    'DELETE/hosting-providers/id': () => hostingProviderDeleteSuccess(),
  };

function glueError(errorResponse: HttpErrorResponse): FesNotification {
  const glueErrorSeparator = '=> ';
  const detail = errorResponse?.error?.errors[0]?.detail;
  const separatorIndex = detail.indexOf(glueErrorSeparator);

  return new FesNotification(
    (separatorIndex > 0
      ? detail?.substring(
          detail.indexOf(glueErrorSeparator) + glueErrorSeparator.length
        )
      : detail) ?? null
  );
}
