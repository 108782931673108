import { environment } from '../../../../../../environments/environment';
import { LAUNCHPAD_ROOT_PATH } from '../../../../../constants';
import { CodeRepositoryConfig } from '../model';

export const GITHUB_CONFIG: CodeRepositoryConfig = {
  baseApi: 'https://api.github.com',
  authorizeUrl: 'https://github.com/login/oauth/authorize',
  acceptHeader: `application/vnd.github.v3+json`,
  windowOptions: 'height=800,width=1200,location=0',
  installUrl: `https://github.com/apps/${environment.github.app}/installations/new`,
  target: 'githubInstallation',
  callback: `${LAUNCHPAD_ROOT_PATH}/github/callback`,
  key: 'github',
};

export const GITHUB_LABELS = {
  install: 'Install GitHub organization',
  authorize: 'Authorize GitHub',
  authorizeHint:
    'To change the configuration, you need to be authorized with GitHub.',
};
