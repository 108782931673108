export enum AuthConfig {
  responseType = 'code',
  responseMode = 'query',
  scope = 'openid profile email offline_access read:tenant_atrs manage:launchpad_organization',
  windowOptions = 'height=800,width=1200,location=0, popup=1',
  codeChallengeMethod = 'S256',
}

export const AUTH_KEY = 'auth0';
export const AUTH_EVENT_NAME = 'authEvent';
