import { Color } from '../../color.model';

export const ruby: Color = {
  light: {
    1: '#fffcfd',
    2: '#fff7f8',
    3: '#feeaed',
    4: '#ffdce1',
    5: '#ffced6',
    6: '#f8bfc8',
    7: '#efacb8',
    8: '#e592a3',
    9: '#e54666',
    10: '#dc3b5d',
    11: '#ca244d',
    12: '#64172b',
  },
  dark: {
    1: '#191113',
    2: '#1e1517',
    3: '#3a141e',
    4: '#4e1325',
    5: '#5e1a2e',
    6: '#6f2539',
    7: '#883447',
    8: '#b3445a',
    9: '#e54666',
    10: '#ec5a72',
    11: '#ff949d',
    12: '#fed2e1',
  },
};
