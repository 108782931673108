<ng-container *ngIf="repositories$ | async as repositories">
  <oryx-select
    data-testid="repository-select"
    label="repository"
    filter
    [filterStrategy]="filterStrategy"
    [isLoading]="repositories | loadingStatusIs: 'LOADING'"
    [isEmpty]="isEmpty()"
    [attr.errorMessage]="
      controls[CodeRepositoryFormFieldsName.REPOSITORY_ID].touched &&
        controls[CodeRepositoryFormFieldsName.REPOSITORY_ID].errors
        | handleInputError
          : {
              required: inputErrorMessages.required
            }
    "
    (focusin)="load()"
  >
    <select
      data-testid="repository-name"
      #repositoryNameInput
      [formControl]="controls[CodeRepositoryFormFieldsName.REPOSITORY_ID]"
      required
      (change)="setRepositoryName($event.target.value)"
      placeholder="Select a repository"
    >
      <option value=""></option>
      <option
        data-testid="repository-name-option"
        *ngFor="let repository of repositories?.value"
        [value]="repository.id"
      >
        {{ repository.name }}
      </option>
    </select>
  </oryx-select>
</ng-container>
