import { HostingApiModel } from '../../../app/models/app-process.model';

export const HostingEventsSuccess = {
  [HostingApiModel.EventNames.install]:
    'Application is installed and being deployed.',
  [HostingApiModel.EventNames.uninstall]:
    'Application uninstalled successfully.',
  [HostingApiModel.EventNames.retryUninstall]:
    'Application uninstalled successfully.',
  [HostingApiModel.EventNames.update]: 'Application updated successfully.',
};

export const HostingEventsError = {
  [HostingApiModel.EventNames.install]:
    'Error: Application could not be installed.',
  [HostingApiModel.EventNames.uninstall]:
    'Error: Application could not be uninstalled.',
  [HostingApiModel.EventNames.retryUninstall]:
    'Error: Application could not be uninstalled.',
  [HostingApiModel.EventNames.update]:
    'Error: Application could not be updated.',
};
