<ng-container *ngIf="branches$ | async as branches">
  <oryx-select
    data-testid="branch-select"
    label="branch"
    filter
    [filterStrategy]="filterStrategy"
    [isLoading]="branches | loadingStatusIs: 'LOADING'"
    [isEmpty]="isEmpty()"
    [appStopPropagation]="['keydown.enter']"
    [attr.errorMessage]="
      controls[CodeRepositoryFormFieldsName.BRANCH].touched &&
        controls[CodeRepositoryFormFieldsName.BRANCH].errors
        | handleInputError
          : {
              required: inputErrorMessages.required
            }
    "
  >
    <input
      data-testid="branch-name"
      [formControl]="controls[CodeRepositoryFormFieldsName.BRANCH]"
      placeholder="Select a branch"
      required
    />
    <oryx-option hidden></oryx-option>
    <oryx-option
      data-testid="branch-name-option"
      *ngFor="let branch of branches?.value"
      [value]="branch"
      [active]="branch === 'master'"
      slot="option"
      >{{ branch }}
    </oryx-option>
  </oryx-select>
</ng-container>
