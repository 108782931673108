import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../../../services/http/http.service';
import { LoaderState } from '../../../../../utils/models/loading.model';
import { CodeRepositoryFormValues, Loader } from '../loader';
import { ClientModel } from '../model';
import { BITBUCKET_CONFIG } from './bitbucket.config';
import { BitbucketApiModel } from './bitbucket.model';

@Injectable()
export class BitbucketLoaderService implements Loader {
  constructor(private httpService: HttpService) {}

  loadOrganizations(): Observable<LoaderState<ClientModel.Organization[]>> {
    return this.httpService.get<
      BitbucketApiModel.BitbucketWorkspacesResponse,
      ClientModel.Organization[]
    >(
      `${BITBUCKET_CONFIG.baseApi}/workspaces`,
      (response: BitbucketApiModel.BitbucketWorkspacesResponse) =>
        response.values.map(
          (workspace: BitbucketApiModel.BitbucketWorkspace) =>
            <ClientModel.Organization>{
              id: workspace.slug,
              name: workspace.name,
            }
        ) ?? []
    );
  }

  loadRepositories({
    organizationId,
  }: CodeRepositoryFormValues): Observable<
    LoaderState<ClientModel.Repository[]>
  > {
    return this.httpService.get<
      BitbucketApiModel.BitbucketRepositoriesResponse,
      ClientModel.Repository[]
    >(
      `${BITBUCKET_CONFIG.baseApi}/repositories/${organizationId}`,
      (response: BitbucketApiModel.BitbucketRepositoriesResponse) =>
        response.values.map(
          (repository: BitbucketApiModel.BitbucketRepository) =>
            ({
              id: repository.slug,
              name: repository.name,
            } ?? [])
        )
    );
  }

  loadBranches({
    repositoryId,
    organizationId,
  }: CodeRepositoryFormValues): Observable<LoaderState<ClientModel.Branch[]>> {
    return this.httpService.get<
      BitbucketApiModel.BitbucketBranchesResponse,
      ClientModel.Branch[]
    >(
      `${BITBUCKET_CONFIG.baseApi}/repositories/${organizationId}/${repositoryId}/refs/branches`,
      (response: BitbucketApiModel.BitbucketBranchesResponse) =>
        response.values.map(
          (branch: BitbucketApiModel.BitbucketBranch) => branch.name
        ) ?? []
    );
  }
}
