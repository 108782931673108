import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { AccessTokenService } from '../auth/access-token.service';
import { AUTH_KEY } from '../auth/auth.config';

@Injectable({
  providedIn: 'root',
})
export class RequireLoggedOutGuard implements CanActivate {
  constructor(
    private router: Router,
    private accessTokenService: AccessTokenService
  ) {}

  canActivate(): Observable<boolean> {
    return this.accessTokenService.get(AUTH_KEY).pipe(
      map((accessToken) => !accessToken),
      tap((isLoggedOut) => {
        if (!isLoggedOut) {
          this.router.navigateByUrl('/');
        }
      })
    );
  }
}
