import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterStrategyType } from '@spryker-oryx/ui/typeahead';
import { BehaviorSubject, delay, distinctUntilChanged, skip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DEFAULT_INPUT_ERROR_MESSAGES } from '../../../../../pipes/handle-input-error/handle-input-error.titles';
import {
  LoaderState,
  LoaderStates,
  LoaderStateStatus,
  LoaderStateValue,
} from '../../../../../utils/models/loading.model';
import { CodeRepositoryFacade } from '../code-repository-facade.service';
import { CodeRepositoryFormService } from '../code-repository-form.service';
import { ClientModel, CodeRepositoryFormFieldsName } from '../model';

@UntilDestroy()
@Component({
  selector: 'pad-repository',
  templateUrl: './repository.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepositoryComponent implements OnInit {
  controls = this.formService.controls;
  filterStrategy = FilterStrategyType.CONTAINS;
  CodeRepositoryFormFieldsName = CodeRepositoryFormFieldsName;
  inputErrorMessages = DEFAULT_INPUT_ERROR_MESSAGES;

  repositories$ = new BehaviorSubject<LoaderState<ClientModel.Repository[]>>(
    LoaderStateValue.Empty<ClientModel.Repository[]>()
  );

  private _repositories$ = this.codeRepositoryFacade.getRepositories();

  ngOnInit(): void {
    this.codeRepositoryFacade
      .getValueOf<string | null>(CodeRepositoryFormFieldsName.REPOSITORY_ID)
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((value: string | null) => {
        if (value && !this.isThereRepositories()) {
          this.repositories$.next(this.addRepositoryFromForm());
        }
      });

    this.codeRepositoryFacade
      .getValueOf<number | null>(CodeRepositoryFormFieldsName.ORGANIZATION_ID)
      .pipe(
        tap((value: number | null) => {
          if (value && this.formService.isFormEnable) {
            this.enableControls();
          } else {
            this.disableControls();
          }
        }),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.resetField();
      });
  }

  load(): void {
    this._repositories$
      .pipe(distinctUntilChanged())
      .subscribe((repositories: LoaderState<ClientModel.Repository[]>) => {
        this.repositories$.next(repositories);
      });
  }

  isEmpty(): boolean {
    const repositories = this.repositories$.value;

    switch (true) {
      case repositories.status === LoaderStateStatus.LOADING:
        return false;

      case repositories.status === LoaderStateStatus.ERROR ||
        repositories.status === LoaderStateStatus.EMPTY:
        return true;

      default:
        return !this.isThereRepositories();
    }
  }

  private addRepositoryFromForm(): LoaderState<ClientModel.Repository[]> {
    return {
      status: LoaderStateStatus.SUCCESS,
      value: [
        {
          id: this.controls.repositoryId.value,
          name: this.controls.repositoryName.value,
          path_with_namespace: this.controls.repositoryPath?.value,
        },
      ],
    };
  }

  private isThereRepositories(): boolean {
    return !!(
      this.repositories$.value as unknown as LoaderStates.NotEmptySuccess<
        ClientModel.Repository[]
      >
    )?.value?.length;
  }

  private getControl(
    name:
      | CodeRepositoryFormFieldsName.REPOSITORY_NAME
      | CodeRepositoryFormFieldsName.REPOSITORY_ID
      | CodeRepositoryFormFieldsName.REPOSITORY_PATH
  ): UntypedFormControl {
    return this.controls[name];
  }

  private getIdControl(): UntypedFormControl {
    return this.getControl(CodeRepositoryFormFieldsName.REPOSITORY_ID);
  }

  private getNameControl(): UntypedFormControl {
    return this.getControl(CodeRepositoryFormFieldsName.REPOSITORY_NAME);
  }

  private getPathControl(): UntypedFormControl | undefined {
    return this.getControl(CodeRepositoryFormFieldsName.REPOSITORY_PATH);
  }

  private enableControls(): void {
    this.getIdControl().enable();
    this.getNameControl().enable();
    this.getPathControl()?.enable();
  }

  private disableControls(): void {
    this.getIdControl().disable();
    this.getNameControl().disable();
    this.getPathControl()?.disable();
  }

  private resetControls(): void {
    this.getIdControl().setValue('');
    this.getNameControl().setValue('');
    this.getPathControl()?.setValue('');
  }

  private resetField(): void {
    this.resetControls();
    this.repositories$.next(LoaderStateValue.Empty<ClientModel.Repository[]>());
    this._repositories$ = this.codeRepositoryFacade.getRepositories();
  }

  constructor(
    private formService: CodeRepositoryFormService,
    private codeRepositoryFacade: CodeRepositoryFacade
  ) {}

  setRepositoryName(id: string): void {
    this.getIdControl().markAsTouched();
    if (!id) {
      this.resetControls();
      return;
    }
    const repositories = this.repositories$.value as LoaderStates.Success<
      ClientModel.Repository[]
    >;
    const repository = repositories.value.find((repo) => repo.id === id);
    this.getNameControl().setValue(repository?.name);
    this.getPathControl()?.setValue(repository?.path_with_namespace);
  }
}
