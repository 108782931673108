import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { FesNotification } from '../../../../brokers/fes-notification.model';
import { ApiModel } from '../utils/json-api';
import { AppEditError, AppEditSuccess } from './app-edit.titles';

export const appEditFetchError = (): FesNotification =>
  new FesNotification(AppEditError.fetch);

export const appEditUpdateSuccess = (): FesNotification =>
  new FesNotification(AppEditSuccess.title, AppEditSuccess.description);

export const appEditUpdateError = (
  error: HttpErrorResponse,
  request: HttpRequest<{ data: Partial<ApiModel.App> }>
): FesNotification =>
  new FesNotification(
    `${getAppName(request?.body?.data as Partial<ApiModel.App>)} ${
      AppEditError.updateSuffix
    }`
  );

function getAppName(data: Partial<ApiModel.App>): string {
  return data?.attributes?.appName ?? '';
}
