export module HostingApiModel {
  export type Processes = {
    data: Process[];
  };

  export interface Process {
    type: 'processes';
    id: string;
    attributes: {
      state: string;
      display: string;
      flags: Flags[];
      availableEvents: EventNames[];
      error?: Error;
    };
  }

  export type EventData = {
    data: Event[];
  };

  export type EventResponse = Partial<Event>;

  export interface EventDataPayload {
    data: Event;
  }

  export interface Error {
    code: number;
    detail: string;
  }

  export interface Event {
    type: string;
    attributes: {
      appProcessId: string;
      eventName: EventNames;
    };
  }

  export enum Flags {
    updatable = 'updatable',
    installed = 'installed',
    error = 'error',
  }

  export enum EventNames {
    install = 'install',
    update = 'update',
    uninstall = 'uninstall',
    retryUninstall = 'retry uninstall',
  }
}

export module HostingClientModel {
  export interface Process {
    type: 'processes';
    id: string;
    state: string;
    display: string;
    flags: HostingApiModel.Flags[];
    availableEvents: HostingApiModel.EventNames[];
    badgeStatus: BadgeStatuses;
    error?: HostingApiModel.Error;
  }

  export enum BadgeStatuses {
    installed = 'installed',
    update = 'needUpdate',
    install = 'needInstall',
    error = 'error',
  }
}

export class AppProcess implements HostingClientModel.Process {
  type: 'processes';
  id: string;
  state: string;
  display: string;
  flags: HostingApiModel.Flags[];
  availableEvents: HostingApiModel.EventNames[];
  badgeStatus: HostingClientModel.BadgeStatuses;
  error?: HostingApiModel.Error;

  constructor(data: HostingApiModel.Process) {
    this.type = data.type;
    this.id = data.id;
    this.state = data.attributes.state;
    this.display = data.attributes.display;
    this.flags = data.attributes.flags;
    this.availableEvents = data.attributes.availableEvents;
    this.error = data.attributes.error;

    switch (true) {
      case this.isFlagIncluded(HostingApiModel.Flags.error):
        this.badgeStatus = HostingClientModel.BadgeStatuses.error;
        break;

      case this.isFlagIncluded(HostingApiModel.Flags.installed) &&
        !this.isEventIncluded(HostingApiModel.EventNames.update):
        this.badgeStatus = HostingClientModel.BadgeStatuses.installed;
        break;

      case this.isEventIncluded(HostingApiModel.EventNames.update):
        this.badgeStatus = HostingClientModel.BadgeStatuses.update;
        break;

      case this.isEventIncluded(HostingApiModel.EventNames.install):
        this.badgeStatus = HostingClientModel.BadgeStatuses.install;
        break;
      default:
        this.badgeStatus = HostingClientModel.BadgeStatuses.error;
    }
  }

  getAvailableEvents(): HostingApiModel.EventNames[] {
    return this.availableEvents;
  }

  isEventIncluded(event: HostingApiModel.EventNames): boolean {
    return this.availableEvents?.includes(event);
  }

  isFlagIncluded(flag: HostingApiModel.Flags): boolean {
    return this.flags?.includes(flag);
  }
}
