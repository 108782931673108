<ng-container *ngIf="viewState$ | async as viewState">
  <ng-container *ngIf="!viewState.isDisabled && !viewState.hasToken">
    <oryx-button
      [type]="ButtonType.Outline"
      [size]="ButtonSize.Lg"
      (click)="authorize()"
    >
      <button slot="custom" data-testid="authorize-github">
        <img icon src="assets/icons/github.svg" alt="Github" />
        {{ labels.authorize }}
      </button>
    </oryx-button>

    <p class="not-authorised">
      {{ labels.authorizeHint }}
    </p>
  </ng-container>

  <div class="code-repository-inputs">
    <div class="org-install-container">
      <pad-organization> </pad-organization>
      <pad-install
        *ngIf="viewState.hasToken && !viewState.isDisabled"
        [label]="labels.install"
        [installUrl]="configuration.installUrl"
        [target]="configuration.target"
        [windowOptions]="configuration.windowOptions"
        [callback]="configuration.callback"
      ></pad-install>
    </div>

    <pad-repository></pad-repository>
    <pad-branch></pad-branch>
  </div>
</ng-container>
