import { InjectionToken } from '@angular/core';
import { Strategy } from '../../../../directives/strategy-container/strategy-container.model';
import { applyFeatureToggle } from '../../../../utils/feature-filter/feature-filter';
import { BitbucketComponent } from './bitbucket/bitbucket.component';
import { BITBUCKET_CONFIG } from './bitbucket/bitbucket.config';
import { GithubComponent } from './github/github.component';
import { GITHUB_CONFIG } from './github/github.config';
import { GitlabComponent } from './gitlab/gitlab.component';
import { GITLAB_CONFIG } from './gitlab/gitlab.config';

export type Provider = Strategy<string> & { name: string; icon: string };

export const PROVIDER_CONFIG_TOKEN = new InjectionToken<Provider[]>(
  'Providers strategies'
);

export enum PROVIDERS_NAMES {
  github = 'github',
  gitlab = 'gitlab',
  bitbucket = 'bitbucket',
}

export const PROVIDERS_LIST = [
  {
    id: PROVIDERS_NAMES.github,
    name: 'Github',
    icon: 'assets/icons/github.svg',
    component: GithubComponent,
    mustRender: (selected: string): boolean =>
      selected === PROVIDERS_NAMES.github,
  },
  {
    id: PROVIDERS_NAMES.gitlab,
    name: 'Gitlab',
    icon: 'assets/icons/gitlab.svg',
    component: GitlabComponent,
    mustRender: (selected: string): boolean =>
      selected === PROVIDERS_NAMES.gitlab,
  },
  {
    id: PROVIDERS_NAMES.bitbucket,
    name: 'Bitbucket',
    icon: 'assets/icons/bitbucket.svg',
    component: BitbucketComponent,
    mustRender: (selected: string): boolean =>
      selected === PROVIDERS_NAMES.bitbucket,
  },
];

export const PROVIDERS: Provider[] = applyFeatureToggle(PROVIDERS_LIST);

export const PROVIDERS_CONFIG = {
  [PROVIDERS_NAMES.github]: GITHUB_CONFIG,
  [PROVIDERS_NAMES.gitlab]: GITLAB_CONFIG,
  [PROVIDERS_NAMES.bitbucket]: BITBUCKET_CONFIG,
};
