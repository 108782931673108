export namespace LoaderStates {
  export enum Status {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    LOADING = 'LOADING',
    EMPTY = 'EMPTY',
  }

  interface Default {
    status: Status;
  }

  export interface Empty extends Default {
    status: Status.EMPTY;
  }

  export interface Error extends Default {
    status: Status.ERROR;
  }

  export interface Loading extends Default {
    status: Status.LOADING;
  }

  export interface EmptySuccess extends Default {
    status: Status.SUCCESS;
  }

  export interface NotEmptySuccess<T> extends Default {
    status: Status.SUCCESS;
    value: T;
  }

  export type Success<T = undefined> = T extends undefined
    ? EmptySuccess
    : NotEmptySuccess<T>;

  export type Union<T> = Success<T> | Loading | Error | Empty;
}

export type LoaderState<T = undefined> = LoaderStates.Union<T>;
export const LoaderStateStatus = LoaderStates.Status;

export namespace LoaderStateValue {
  export const Empty = <T>(): LoaderState<T> =>
    ({
      status: LoaderStateStatus.EMPTY,
    } as LoaderState<T>);
}
