import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  glueBaseApi: 'https://glue.faas-test.cloud.spryker.toys',
  github: {
    app: 'spryker-launchpad-test',
    clientId: 'Iv1.82d621683fbc4d32',
  },
  gitlab: {
    app: 'spryker-launchpad-test',
    clientId:
      '2c1443e300171a16ae3a493a768ac6afbb27c2a7053400d618beb878eb68dc20',
  },
  bitbucket: {
    app: 'spryker-launchpad-test',
    clientId: 'jDy28AgptNy5Wayz8C',
  },
  auth: {
    authorizeUrl: 'https://dev-163i904u.us.auth0.com/authorize',
    clientId: 'vKj4EtukysLZ8vE2fdxdAQjDDxZtrFw9',
    audience: 'http://localhost:3001',
    redirectUrl: 'https://test.launchpad.spryker.com/callback/auth.html',
    logoutUrl: 'https://dev-163i904u.us.auth0.com/v2/logout',
  },
  atrs: {
    baseUrl: 'https://api.atrs-staging.demo-spryker.com',
  },
  experienceBuilder: {
    baseUrl: 'https://test-experience-builder.netlify.app',
    iframeMode: true,
  },
};
