import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../../../services/http/http.service';
import { LoaderState } from '../../../../../utils/models/loading.model';
import { GitLabApiModel } from '../gitlab/gitlab.model';
import { CodeRepositoryFormValues, Loader } from '../loader';
import { ClientModel } from '../model';
import { GITHUB_CONFIG } from './github.config';
import { GitHubApiModel } from './github.model';

@Injectable()
export class GithubLoaderService implements Loader {
  constructor(private httpService: HttpService) {}

  loadOrganizations(): Observable<LoaderState<ClientModel.Organization[]>> {
    return this.httpService.get<
      GitHubApiModel.GitHubOrganizationsResponse,
      ClientModel.Organization[]
    >(
      `${GITHUB_CONFIG.baseApi}/user/installations?timestamp=${Date.now()}`,
      (response: GitHubApiModel.GitHubOrganizationsResponse) =>
        response.installations.map(
          (organization: GitHubApiModel.GitHubOrganization) =>
            <ClientModel.Organization>{
              id: organization.id.toString(),
              name: organization.account?.login,
            }
        ) ?? []
    );
  }

  loadRepositories({
    organizationId,
  }: CodeRepositoryFormValues): Observable<
    LoaderState<ClientModel.Repository[]>
  > {
    return this.httpService.get<
      GitHubApiModel.GitHubRepositoriesResponse,
      ClientModel.Repository[]
    >(
      `${
        GITHUB_CONFIG.baseApi
      }/user/installations/${organizationId}/repositories?timestamp=${Date.now()}`,
      (response: GitHubApiModel.GitHubRepositoriesResponse) =>
        response.repositories?.map((repo: GitHubApiModel.GitHubRepository) => ({
          name: repo.name,
          id: repo.name,
        })) ?? []
    );
  }

  loadBranches({
    organizationName,
    repositoryId,
  }: CodeRepositoryFormValues): Observable<LoaderState<ClientModel.Branch[]>> {
    return this.httpService.get<
      GitLabApiModel.GitLabBranchResponse[],
      ClientModel.Branch[]
    >(
      `${
        GITHUB_CONFIG.baseApi
      }/repos/${organizationName}/${repositoryId}/branches?timestamp=${Date.now()}`,
      (response: GitLabApiModel.GitLabBranchResponse[]) =>
        response.map(
          (branch: GitLabApiModel.GitLabBranchResponse) => branch.name
        ) ?? []
    );
  }
}
