import { Color } from '../../color.model';

export const spryker: Color = {
  light: {
    0: 'hsl(0, 0%, 0%)',
    1: '#FBFEFD',
    2: '#F8FDFC',
    3: '#F4FCFA',
    4: '#EAF9F6',
    5: '#E3F7F3',
    6: '#ABE7DB',
    7: '#72D6C4',
    8: '#56CEB8',
    9: '#1EBEA0',
    10: '#1AA68C',
    11: '#0F5F50',
    12: '#093A31',
  },
  dark: {
    0: 'hsl(0, 0%, 0%)',
    1: '#06231D',
    2: '#082F27',
    3: '#09362D',
    4: '#0A3D33',
    5: '#0C4C40',
    6: '#0F5C4E',
    7: '#126E5D',
    8: '#147D6A',
    9: '#1EBEA0',
    10: '#3AC6AC',
    11: '#53CDB6',
    12: '#093A31',
    // 12: '#F6FFFD',
  },
};
