import { Theme } from '@spryker-oryx/experience';
import { backofficeIcons, fontawesomeIcons } from '@spryker-oryx/resources';
import { defaultBreakpoints } from '@spryker-oryx/themes/breakpoints';

export const launchpadTheme: Theme = {
  name: 'fes',
  breakpoints: defaultBreakpoints,
  designTokens: () => import('./design-tokens').then((s) => s.launchpadTokens),
  icons: {
    resource: fontawesomeIcons,
    resources: [
      {
        resource: backofficeIcons,
        types: Object.keys(backofficeIcons.mapping ?? {}),
      },
    ],
  },
};
