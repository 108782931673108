import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterStrategyType } from '@spryker-oryx/ui/typeahead';
import { BehaviorSubject, delay, distinctUntilChanged, skip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DEFAULT_INPUT_ERROR_MESSAGES } from '../../../../../pipes/handle-input-error/handle-input-error.titles';
import {
  LoaderState,
  LoaderStates,
  LoaderStateStatus,
  LoaderStateValue,
} from '../../../../../utils/models/loading.model';
import { CodeRepositoryFacade } from '../code-repository-facade.service';
import { CodeRepositoryFormService } from '../code-repository-form.service';
import { ClientModel, CodeRepositoryFormFieldsName } from '../model';

@UntilDestroy()
@Component({
  selector: 'pad-branch',
  templateUrl: './branch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchComponent implements OnInit {
  // TODO: refactor it by normal observable approach also fix types
  controls = this.formService.controls;
  filterStrategy = FilterStrategyType.CONTAINS;
  CodeRepositoryFormFieldsName = CodeRepositoryFormFieldsName;
  inputErrorMessages = DEFAULT_INPUT_ERROR_MESSAGES;

  branches$ = new BehaviorSubject(
    LoaderStateValue.Empty<ClientModel.Branch[]>()
  );
  private _branches$ = this.codeRepositoryFacade.getBranches();

  ngOnInit(): void {
    this.codeRepositoryFacade
      .getValueOf<string | null>(CodeRepositoryFormFieldsName.BRANCH)
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((value: string | null) => {
        if (value && !this.isThereBranches()) {
          this.branches$.next(this.addBranchFromForm());
        }
      });

    this.codeRepositoryFacade
      .getValueOf<string | null>(CodeRepositoryFormFieldsName.REPOSITORY_ID)
      .pipe(
        tap((value: string | null) => {
          if (value && this.formService.isFormEnable) {
            this.getBranchControl().enable();
          } else {
            this.getBranchControl().disable();
          }
        }),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.resetField();
        this.load();
      });
  }

  load(): void {
    this._branches$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: LoaderState<ClientModel.Branch[]>) => {
        this.branches$.next(value);

        if ((value as any).value && (value as any).value.includes('master')) {
          this.getBranchControl().setValue('master');
        }
      });
  }

  isEmpty(): boolean {
    const branches = this.branches$.value;

    switch (true) {
      case branches.status === LoaderStateStatus.LOADING:
        return false;

      case branches.status === LoaderStateStatus.ERROR ||
        branches.status === LoaderStateStatus.EMPTY:
        return true;

      default:
        return !this.isThereBranches();
    }
  }

  private resetField(): void {
    this.getBranchControl().reset();
    this.branches$.next(LoaderStateValue.Empty<ClientModel.Branch[]>());
    this._branches$ = this.codeRepositoryFacade.getBranches();
  }

  private addBranchFromForm(): LoaderState<ClientModel.Branch[]> {
    return {
      status: LoaderStateStatus.SUCCESS,
      value: [this.formService.controls.branch.value],
    };
  }

  private getBranchControl(): UntypedFormControl {
    return this.controls[CodeRepositoryFormFieldsName.BRANCH];
  }

  private isThereBranches(): boolean {
    return !!(
      this.branches$.value as unknown as LoaderStates.NotEmptySuccess<
        ClientModel.Branch[]
      >
    )?.value?.length;
  }

  constructor(
    private formService: CodeRepositoryFormService,
    private codeRepositoryFacade: CodeRepositoryFacade
  ) {}
}
