import {
  HttpErrorResponse,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NotificationsService } from '../notifications/notifications.service';
import {
  FES_ERROR_MAP_TOKEN,
  FES_SUCCESS_MAP_TOKEN,
} from './fes-notification-config-map.token';
import {
  FesNotificationErrorConfig,
  FesNotificationSuccessConfig,
} from './fes-notification.model';
import { RequestIdentifier } from './request-identifier/request-identifier.service';

@Injectable({ providedIn: 'root' })
export class NotificationBroker {
  constructor(
    private notificationsService: NotificationsService,
    @Inject(FES_ERROR_MAP_TOKEN)
    private fesNotificationErrorConfig: FesNotificationErrorConfig,
    @Inject(FES_SUCCESS_MAP_TOKEN)
    private fesNotificationSuccessConfig: FesNotificationSuccessConfig,
    private requestIdentifierService: RequestIdentifier
  ) {}

  public handleError(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>
  ): void {
    const identifier = this.requestIdentifierService.getIdentifier(request);

    if (identifier && this.fesNotificationErrorConfig[identifier]) {
      const { title, description } = this.fesNotificationErrorConfig[
        identifier
      ](error, request);

      if (title) {
        this.notificationsService.error(title, description);
      }
    }
  }

  public handleSuccess(
    response: HttpResponse<unknown>,
    request: HttpRequest<unknown>
  ): void {
    const identifier = this.requestIdentifierService.getIdentifier(request);

    if (identifier && this.fesNotificationSuccessConfig[identifier]) {
      const { title, description } = this.fesNotificationSuccessConfig[
        identifier
      ](response, request);

      if (title) {
        this.notificationsService.success(title, description);
      }
    }
  }
}
