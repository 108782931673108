import { LAUNCHPAD_ROOT_PATH } from '../../../../../constants';
import { CodeRepositoryConfig } from '../model';

export const BITBUCKET_CONFIG: Omit<CodeRepositoryConfig, 'installUrl'> = {
  baseApi: 'https://api.bitbucket.org/2.0',
  authorizeUrl: 'https://bitbucket.org/site/oauth2/authorize',
  acceptHeader: `application/json`,
  windowOptions: 'height=800,width=1200,location=0',
  target: 'bitbucketInstallation',
  callback: `${LAUNCHPAD_ROOT_PATH}/bitbucket/callback`,
  key: 'bitbucket',
};

export const BITBUCKET_LABELS = {
  authorize: 'Authorize Bitbucket',
  authorizeHint:
    'To change the configuration, you need to be authorized with Bitbucket.',
};
