import { ChangeDetectionStrategy, Component } from '@angular/core';
import { appBuilder } from '@spryker-oryx/application';
import { launchpadTheme } from '../theme/launchpad.theme';
import { launchpadFeatures } from './app.features';

@Component({
  selector: 'pad-root',
  templateUrl: './root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootComponent {
  constructor() {
    appBuilder()
      .withFeature(launchpadFeatures)
      .withTheme(launchpadTheme)
      .create();
  }
}
