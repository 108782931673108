import { Color } from '../../color.model';

export const mint: Color = {
  light: {
    0: 'hsl(0, 0%, 0%)',
    1: 'hsl(165, 80.0%, 98.8%)',
    2: 'hsl(164, 88.2%, 96.7%)',
    3: 'hsl(164, 76.6%, 93.3%)',
    4: 'hsl(165, 68.8%, 89.5%)',
    5: 'hsl(165, 60.6%, 84.5%)',
    6: 'hsl(165, 53.5%, 76.9%)',
    7: 'hsl(166, 50.7%, 66.1%)',
    8: 'hsl(168, 52.8%, 51.0%)',
    9: 'hsl(167, 65.0%, 66.0%)',
    10: 'hsl(167, 59.3%, 63.1%)',
    11: 'hsl(172, 72.0%, 28.5%)',
    12: 'hsl(172, 70.0%, 12.0%)',
  },
  dark: {
    0: 'hsl(0, 0%, 0%)',
    1: 'hsl(173, 50.0%, 6.6%)',
    2: 'hsl(176, 73.0%, 7.3%)',
    3: 'hsl(175, 79.3%, 8.9%)',
    4: 'hsl(174, 84.8%, 10.3%)',
    5: 'hsl(174, 90.2%, 11.9%)',
    6: 'hsl(173, 96.0%, 13.8%)',
    7: 'hsl(172, 100%, 16.8%)',
    8: 'hsl(170, 100%, 21.4%)',
    9: 'hsl(167, 65.0%, 66.0%)',
    10: 'hsl(163, 80.0%, 77.0%)',
    11: 'hsl(167, 70.0%, 48.0%)',
    12: 'hsl(165, 80.0%, 94.8%)',
  },
};
