import { Color } from '../../color.model';

export const sage: Color = {
  light: {
    1: 'hsl(155, 30.0%, 98.8%)',
    2: 'hsl(150, 16.7%, 97.6%)',
    3: 'hsl(151, 10.6%, 95.2%)',
    4: 'hsl(151, 8.8%, 93.0%)',
    5: 'hsl(151, 7.8%, 90.8%)',
    6: 'hsl(152, 7.2%, 88.4%)',
    7: 'hsl(153, 6.7%, 85.3%)',
    8: 'hsl(154, 6.1%, 77.5%)',
    9: 'hsl(155, 3.5%, 55.5%)',
    10: 'hsl(154, 2.8%, 51.7%)',
    11: 'hsl(155, 3.0%, 43.0%)',
    12: 'hsl(155, 24.0%, 9.0%)',
  },
  dark: {
    1: 'hsl(155, 7.0%, 8.4%)',
    2: 'hsl(150, 7.4%, 10.6%)',
    3: 'hsl(150, 6.7%, 13.1%)',
    4: 'hsl(150, 6.4%, 15.3%)',
    5: 'hsl(150, 6.1%, 17.4%)',
    6: 'hsl(150, 5.8%, 19.9%)',
    7: 'hsl(150, 5.5%, 23.6%)',
    8: 'hsl(150, 5.1%, 30.6%)',
    9: 'hsl(155, 6.0%, 42.5%)',
    10: 'hsl(153, 4.8%, 48.2%)',
    11: 'hsl(155, 5.0%, 61.8%)',
    12: 'hsl(155, 6.0%, 93.0%)',
  },
};
