import { Color } from '../../color.model';

export const jade: Color = {
  light: {
    1: '#fbfefd',
    2: '#f4fbf7',
    3: '#e6f7ed',
    4: '#d6f1e3',
    5: '#c3e9d7',
    6: '#acdec8',
    7: '#8bceb6',
    8: '#56ba9f',
    9: '#29a383',
    10: '#26997b',
    11: '#208368',
    12: '#1d3b31',
  },
  dark: {
    1: '#0d1512',
    2: '#121c18',
    3: '#0f2e22',
    4: '#0b3b2c',
    5: '#114837',
    6: '#1b5745',
    7: '#246854',
    8: '#2a7e68',
    9: '#29a383',
    10: '#27b08b',
    11: '#1fd8a4',
    12: '#adf0d4',
  },
};
