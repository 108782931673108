import { Color } from '../../color.model';

export const blue: Color = {
  light: {
    1: 'hsl(206, 100%, 99.2%)',
    2: 'hsl(210, 100%, 98%)',
    3: 'hsl(209, 100%, 96.5%)',
    4: 'hsl(210, 98.8%, 94%)',
    5: 'hsl(209, 95%, 90.1%)',
    6: 'hsl(209, 81.2%, 84.5%)',
    7: 'hsl(208, 77.5%, 76.9%)',
    8: 'hsl(206, 81.9%, 65.3%)',
    9: 'hsl(206, 100%, 50%)',
    10: 'hsl(208, 100%, 47.3%)',
    11: 'hsl(211, 100%, 43.2%)',
    12: 'hsl(211, 100%, 15%)',
  },
  dark: {
    1: 'hsl(212, 35%, 9.2%)',
    2: 'hsl(216, 50%, 11.8%)',
    3: 'hsl(214, 59.4%, 15.3%)',
    4: 'hsl(214, 65.8%, 17.9%)',
    5: 'hsl(213, 71.2%, 20.2%)',
    6: 'hsl(212, 77.4%, 23.1%)',
    7: 'hsl(211, 85.1%, 27.4%)',
    8: 'hsl(211, 89.7%, 34.1%)',
    9: 'hsl(206, 100%, 50%)',
    10: 'hsl(209, 100%, 60.6%)',
    11: 'hsl(210, 100%, 66.1%)',
    12: 'hsl(206, 98%, 95.8%)',
  },
};
