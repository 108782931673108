import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { DEFAULT_INPUT_ERROR_MESSAGES } from '../../../../../pipes/handle-input-error/handle-input-error.titles';
import {
  LoaderState,
  LoaderStates,
  LoaderStateStatus,
  LoaderStateValue,
} from '../../../../../utils/models/loading.model';
import { CodeRepositoryFacade } from '../code-repository-facade.service';
import { CodeRepositoryFormService } from '../code-repository-form.service';
import { ClientModel, CodeRepositoryFormFieldsName } from '../model';

@UntilDestroy()
@Component({
  selector: 'pad-organization',
  templateUrl: './organization.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationComponent implements OnInit {
  PLACEHOLDER_VALUE = '';
  inputErrorMessages = DEFAULT_INPUT_ERROR_MESSAGES;

  controls = this.formService.controls;
  CodeRepositoryFormFieldsName = CodeRepositoryFormFieldsName;

  organizations$ = new BehaviorSubject<LoaderState<ClientModel.Organization[]>>(
    LoaderStateValue.Empty<ClientModel.Organization[]>()
  );

  private _organizations$ = this.codeRepositoryFacade.getOrganizations();

  ngOnInit(): void {
    this.codeRepositoryFacade
      .getValueOf<string | null>(CodeRepositoryFormFieldsName.ORGANIZATION_ID)
      .pipe(untilDestroyed(this))
      .subscribe((value: string | null) => {
        if (value && !this.isValueOnTheList(value)) {
          this.organizations$.next(this.addOrganizationFromForm());
        }
      });
  }

  load(): void {
    this._organizations$
      .pipe(distinctUntilChanged())
      .subscribe((organizations: LoaderState<ClientModel.Organization[]>) => {
        this.organizations$.next(organizations);
      });
  }

  select(event: Event): void {
    const organizations = (
      this.organizations$.value as LoaderStates.NotEmptySuccess<
        ClientModel.Organization[]
      >
    ).value;

    const orgId = (event.target as HTMLSelectElement).value;

    if (orgId) {
      const org = organizations.find(
        (o: ClientModel.Organization) => o.id === orgId
      );
      this.controls.organizationName?.setValue(org?.name);
      this.controls.organizationName.markAsTouched();
      this.controls.organizationId.markAsTouched();
    } else {
      this.controls.organizationId.setValue(this.PLACEHOLDER_VALUE);
      this.controls.organizationName.reset();
    }
  }

  isEmpty(): boolean {
    const organizations = this.organizations$.value;

    switch (true) {
      case organizations.status === LoaderStateStatus.LOADING:
        return false;

      case organizations.status === LoaderStateStatus.ERROR ||
        organizations.status === LoaderStateStatus.EMPTY:
        return true;

      default:
        return !this.isThereOrganizations();
    }
  }

  private isValueOnTheList(value: string): boolean {
    if (!this.isThereOrganizations()) {
      return false;
    }

    return !!(
      this.organizations$.value as LoaderStates.NotEmptySuccess<
        ClientModel.Organization[]
      >
    ).value?.find(
      (organization: ClientModel.Organization) => organization.id === value
    );
  }

  private isThereOrganizations(): boolean {
    return !!(
      this.organizations$.value as LoaderStates.NotEmptySuccess<
        ClientModel.Organization[]
      >
    ).value?.length;
  }

  private addOrganizationFromForm(): LoaderState<ClientModel.Organization[]> {
    return {
      status: LoaderStateStatus.SUCCESS,
      value: [
        {
          name: this.controls.organizationName?.value,
          id: this.controls.organizationId.value,
        },
      ],
    };
  }

  constructor(
    private formService: CodeRepositoryFormService,
    private codeRepositoryFacade: CodeRepositoryFacade
  ) {}
}
