import { Observable } from 'rxjs';
import { LoaderState } from '../../../../utils/models/loading.model';
import { ClientModel, CodeRepositoryFormFieldsName } from './model';

export type CodeRepositoryFormValues = Partial<
  Record<CodeRepositoryFormFieldsName, string>
>;

export abstract class Loader {
  abstract loadOrganizations(): Observable<
    LoaderState<ClientModel.Organization[]>
  >;

  abstract loadRepositories(
    codeRepositoryFormValues: CodeRepositoryFormValues
  ): Observable<LoaderState<ClientModel.Repository[]>>;

  abstract loadBranches(
    codeRepositoryFormValues: CodeRepositoryFormValues
  ): Observable<LoaderState<ClientModel.Branch[]>>;
}
