import { Injectable } from '@angular/core';
import { AlertType } from '@spryker-oryx/ui';
import {
  NotificationPosition,
  NotificationService,
} from '@spryker-oryx/ui/notification-center';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly SUCCESS_AUTO_CLOSE_TIME = 3000;
  private readonly ERROR_AUTO_CLOSE_TIME = 6000;

  constructor(private notificationService: NotificationService) {}

  success(title: string, description?: string): void {
    this.notificationService
      .getCenter('body', NotificationPosition.TopEnd)
      .open({
        closable: true,
        type: AlertType.Success,
        content: title,
        subtext: description,
        autoClose: true,
        autoCloseTime: this.SUCCESS_AUTO_CLOSE_TIME,
      });
  }

  error(title: string, description?: string): void {
    this.notificationService
      .getCenter('body', NotificationPosition.TopEnd)
      .open({
        closable: true,
        type: AlertType.Error,
        content: title,
        subtext: description,
        autoClose: true,
        autoCloseTime: this.ERROR_AUTO_CLOSE_TIME,
      });
  }
}
