import { HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  RequestIdentifierStrategy,
  REQUEST_IDENTIFIER_STRATEGIES_TOKEN,
} from './request-identifier.model';

@Injectable({ providedIn: 'root' })
export class RequestIdentifier {
  constructor(
    @Inject(REQUEST_IDENTIFIER_STRATEGIES_TOKEN)
    private requestIdentifierStrategies: RequestIdentifierStrategy[]
  ) {}

  getIdentifier(request: HttpRequest<unknown>): string {
    const strategy = this.requestIdentifierStrategies.find(
      (strategy: RequestIdentifierStrategy) => strategy.canHandle(request)
    );

    return strategy ? strategy.getIdentifier(request) : request.url;
  }
}
