import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FEATURES } from '../../app/app-form/app-features/app.model';
import { AppFormStateService } from '../../app/app-form/app-form-state.service';
import { ClientModel } from '../../app/utils/json-api';
import {
  CodeRepositoryFormFields,
  CodeRepositoryFormFieldsName,
} from './model';

const fieldMap = {
  'organisation-id': CodeRepositoryFormFieldsName.ORGANIZATION_ID,
  'organisation-name': CodeRepositoryFormFieldsName.ORGANIZATION_NAME,
  'repository-id': CodeRepositoryFormFieldsName.REPOSITORY_ID,
  'repository-name': CodeRepositoryFormFieldsName.REPOSITORY_NAME,
  'repository-path': CodeRepositoryFormFieldsName.REPOSITORY_PATH,
  branch: CodeRepositoryFormFieldsName.BRANCH,
};
@Injectable()
export class CodeRepositoryFormService {
  form: UntypedFormGroup = new UntypedFormGroup({});
  controls!: CodeRepositoryFormFields;

  private storedForms: Map<ClientModel.AppFeatureFields, UntypedFormGroup> =
    new Map();

  get isFormEnable(): boolean {
    return this.form.enabled;
  }

  useFeature(feature: ClientModel.AppFeatureFields): void {
    if (this.storedForms.has(feature)) {
      this.useExistingFeatureForm(feature);
    } else {
      this.createFeatureForm(feature);
    }
  }

  disable(): void {
    this.form.disable({ onlySelf: true, emitEvent: false });
  }

  enable(): void {
    this.form.enable({ onlySelf: true, emitEvent: false });
  }

  private useExistingFeatureForm(feature: ClientModel.AppFeatureFields) {
    const form = this.storedForms.get(feature) as UntypedFormGroup;
    this.form = form;
    this.appFormStateService.remove(FEATURES.code_repository);
    this.appFormStateService.register(FEATURES.code_repository, () => form);
    this.controls = this.resolveControlsFromForm(
      form,
      feature.fields as ClientModel.AppFeatureField[]
    );
  }

  private createFeatureForm(feature: ClientModel.AppFeatureFields) {
    const form = this.buildForm(feature.fields);

    this.appFormStateService.remove(FEATURES.code_repository);
    this.appFormStateService.register(FEATURES.code_repository, (defaults) =>
      this.setFormValues(form, defaults)
    );

    this.controls = this.resolveControlsFromForm(
      form,
      feature.fields as ClientModel.AppFeatureField[]
    );
    this.storedForms.set(feature, form);
    this.form = form;
  }

  private buildForm(fields?: ClientModel.AppFeatureField[]): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    const controls: CodeRepositoryFormFields = {
      [CodeRepositoryFormFieldsName.ORGANIZATION_ID]: new UntypedFormControl(
        '',
        Validators.required
      ),
      [CodeRepositoryFormFieldsName.ORGANIZATION_NAME]: new UntypedFormControl(
        '',
        Validators.required
      ),
      [CodeRepositoryFormFieldsName.REPOSITORY_ID]: new UntypedFormControl(
        '',
        Validators.required
      ),
      [CodeRepositoryFormFieldsName.REPOSITORY_NAME]: new UntypedFormControl(
        '',
        Validators.required
      ),
      [CodeRepositoryFormFieldsName.REPOSITORY_PATH]: new UntypedFormControl(
        '',
        Validators.required
      ),
      [CodeRepositoryFormFieldsName.BRANCH]: new UntypedFormControl(
        '',
        Validators.required
      ),
    };
    this.controls = controls;
    Object.entries(fieldMap).forEach(([key, prop]) => {
      const id = fields?.find(
        (field: ClientModel.AppFeatureField) => field.key === key
      )?.id;
      if (id) {
        const control = controls[prop as keyof CodeRepositoryFormFields];
        form.addControl(id, control);
      }
    });
    return form;
  }

  private setFormValues(
    form: UntypedFormGroup,
    defaults?: ClientModel.App
  ): UntypedFormGroup {
    const formValue =
      defaults?.fields?.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item.value,
        }),
        {}
      ) ?? {};

    form.patchValue(formValue);

    return form;
  }

  private resolveControlsFromForm(
    form: UntypedFormGroup,
    fields: ClientModel.AppFeatureField[]
  ): CodeRepositoryFormFields {
    const controls: Partial<CodeRepositoryFormFields> = {};
    Object.entries(fieldMap).forEach(([key, prop]) => {
      const id = fields?.find(
        (field: ClientModel.AppFeatureField) => field.key === key
      )?.id;
      if (id) {
        const control = form.get(id);
        controls[prop] = control as UntypedFormControl;
      }
    });

    return controls as CodeRepositoryFormFields;
  }

  constructor(private appFormStateService: AppFormStateService) {}
}
