import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  fromEvent,
  map,
  Observable,
  Subscription,
  tap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientStorageService<T> implements OnDestroy {
  private tokens$ = new Map<string, BehaviorSubject<T | null>>();
  private subscriptions = new Subscription();

  get(key: string): Observable<T | null> {
    return this.getSubject(key).asObservable();
  }

  getValue(key: string): T | null {
    return this.getSubject(key).value;
  }

  set(key: string, value: T): void {
    this.syncToStorage(key, value);
    this.getSubject(key).next(value);
  }

  remove(key: string): void {
    const subject = this.getSubject(key);
    this.syncToStorage(key);
    subject.pipe(
      map(() => {
        this.deleteSubject(key);
      })
    );
    subject.next(null);
  }

  clear(): void {
    this.storage.clear();
    this.tokens$.clear();
  }

  private deleteSubject(key: string): void {
    if (this.tokens$.has(key)) {
      this.tokens$.delete(key);
    }
  }

  private getSubject(key: string): BehaviorSubject<T | null> {
    if (!this.tokens$.has(key)) {
      this.tokens$.set(key, new BehaviorSubject<T | null>(null));
      const value = this.storage.getItem(key);
      // initialize token with value from local storage
      // (nodejs tying doesn't support `hasItem()` which is why our mocked tests fails)
      if (value) {
        (this.tokens$.get(key) as BehaviorSubject<T | null>).next(
          JSON.parse(value)
        );
      }
    }

    return this.tokens$.get(key) as BehaviorSubject<T | null>;
  }

  private syncToStorage(key: string, value?: T): void {
    if (value) {
      this.storage.setItem(key, JSON.stringify(value));
    } else {
      this.storage.removeItem(key);
    }
  }

  private syncFromStorage(): void {
    this.subscriptions.add(
      fromEvent<StorageEvent>(window, 'storage')
        .pipe(
          tap((event) => {
            if (!event?.key && this.tokens$.size) {
              this.resetTokensMap();
            }
          }),
          filter((event) => !!event?.key && this.tokens$.has(event.key))
        )
        .subscribe((event) => {
          const { key, newValue } = event;

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.setTokenValue(key!, newValue);
        })
    );
  }

  private resetTokensMap(): void {
    Array.from(this.tokens$.keys()).forEach((key) => {
      const valueFromStoreByKey = this.storage.getItem(key);

      this.setTokenValue(key, valueFromStoreByKey);
    });
  }

  private setTokenValue(key: string, value: string | null): void {
    if (value) {
      this.tokens$.get(key)?.next(JSON.parse(value));
    } else {
      this.tokens$.get(key)?.next(null);
    }
  }

  private get storage(): Storage {
    return localStorage;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  constructor() {
    this.syncFromStorage();
  }
}
