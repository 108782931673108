import { Color } from '../../color.model';

export const tomato: Color = {
  light: {
    1: 'hsl(10, 100%, 99.4%)',
    2: 'hsl(8, 100%, 98.4%)',
    3: 'hsl(8, 100%, 96.6%)',
    4: 'hsl(8, 100%, 94.3%)',
    5: 'hsl(8, 92.8%, 91.0%)',
    6: 'hsl(9, 84.7%, 86.3%)',
    7: 'hsl(10, 77.3%, 79.5%)',
    8: 'hsl(10, 71.6%, 71.0%)',
    9: 'hsl(10, 78.0%, 54.0%)',
    10: 'hsl(10, 71.5%, 50.0%)',
    11: 'hsl(10, 82.0%, 43.5%)',
    12: 'hsl(10, 50.0%, 13.5%)',
  },
  dark: {
    1: 'hsl(10, 23.0%, 9.4%)',
    2: 'hsl(9, 44.8%, 11.4%)',
    3: 'hsl(8, 52.0%, 15.3%)',
    4: 'hsl(7, 56.3%, 18.0%)',
    5: 'hsl(7, 60.1%, 20.6%)',
    6: 'hsl(8, 64.8%, 24.0%)',
    7: 'hsl(8, 71.2%, 29.1%)',
    8: 'hsl(10, 80.2%, 35.7%)',
    9: 'hsl(10, 78.0%, 54.0%)',
    10: 'hsl(10, 81.7%, 59.0%)',
    11: 'hsl(10, 85.0%, 62.8%)',
    12: 'hsl(10, 89.0%, 96.0%)',
  },
};
