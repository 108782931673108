<ng-container *ngIf="organizations$ | async as organizations">
  <oryx-select
    label="organization"
    data-testid="organization-select"
    [isLoading]="organizations | loadingStatusIs: 'LOADING'"
    [isEmpty]="isEmpty()"
    (focusin)="load()"
    [attr.errorMessage]="
      controls[CodeRepositoryFormFieldsName.ORGANIZATION_ID].touched &&
        controls[CodeRepositoryFormFieldsName.ORGANIZATION_ID].errors
        | handleInputError
          : {
              required: inputErrorMessages.required
            }
    "
  >
    <select
      data-testid="organization-native-select"
      (change)="select($event)"
      required
      [formControl]="controls[CodeRepositoryFormFieldsName.ORGANIZATION_ID]"
      placeholder="Select an organization"
    >
      <option></option>
      <option
        data-testid="organization-option"
        *ngFor="let organization of organizations?.value"
        [value]="organization.id"
      >
        {{ organization.name }}
      </option>
    </select>
  </oryx-select>
</ng-container>
