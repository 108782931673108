import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, Observable, take, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccessTokenService } from '../auth/access-token.service';
import { AUTH_KEY } from '../auth/auth.config';
import { NotificationBroker } from '../brokers/fes-notification.broker';

const { glueBaseApi, atrs } = environment;

@Injectable()
export class FesAuthInterceptor implements HttpInterceptor {
  constructor(
    private accessTokenService: AccessTokenService,
    private notificationBroker: NotificationBroker
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let paramReq: HttpRequest<unknown> = request.clone();

    if (this.isGlueApi(request)) {
      return this.accessTokenService.get(AUTH_KEY).pipe(
        take(1),
        mergeMap((token) => {
          if (token) {
            paramReq = request.clone({
              setHeaders: {
                Authorization: `${token.tokenType} ${token.accessToken}`,
              },
            });
          }

          return next.handle(paramReq).pipe(this.handleResponse(request));
        })
      );
    }

    return next.handle(paramReq).pipe(this.handleResponse(request));
  }

  private handleResponseError(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> {
    this.notificationBroker.handleError(error, request);
    return throwError(() => error);
  }

  private handleResponseSuccess(
    response: HttpResponse<unknown>,
    request: HttpRequest<unknown>
  ): void {
    this.notificationBroker.handleSuccess(response, request);
  }

  private isGlueApi(request: HttpRequest<unknown>): boolean {
    return (
      (request.url.includes(glueBaseApi) &&
        !request.url.includes(`${AUTH_KEY}/token`)) ||
      request.url.includes(atrs.baseUrl)
    );
  }

  private handleResponse =
    (request: HttpRequest<unknown>) =>
    (source$: Observable<any>): Observable<HttpEvent<unknown>> =>
      source$.pipe(
        tap((response) => {
          if (response.body !== undefined) {
            this.handleResponseSuccess(response, request);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleResponseError(error, request);
        })
      );
}
