import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { AccessTokenService } from '../../../../../auth/access-token.service';
import { AppFormStateService } from '../../../app/app-form/app-form-state.service';
import { DefaultButtonType } from '../../../app/utils/default-button-type';
import { DefaultSize } from '../../../app/utils/default-size';
import { ClientModel } from '../../../app/utils/json-api';
import { CodeRepositoryFacade } from '../code-repository-facade.service';
import { CodeRepositoryFormService } from '../code-repository-form.service';
import { Loader } from '../loader';
import { FEATURE_PROVIDERS } from '../model';
import { ProvidersAuthService } from '../providers-auth.service';
import { GithubLoaderService } from './github-loader.service';
import { GITHUB_CONFIG, GITHUB_LABELS } from './github.config';

@Component({
  selector: 'pad-github',
  templateUrl: './github.component.html',
  styleUrls: ['../styles/provider.components.scss'],
  providers: [
    {
      provide: 'feature',
      useFactory: (injector: Injector) =>
        injector.get('featureData')[FEATURE_PROVIDERS.GITHUB],
      deps: [Injector],
    },
    CodeRepositoryFormService,
    CodeRepositoryFacade,
    {
      provide: Loader,
      useClass: GithubLoaderService,
    },
  ],
})
export class GithubComponent implements OnInit {
  ButtonSize = DefaultSize;

  ButtonType = DefaultButtonType;

  configuration = GITHUB_CONFIG;
  labels = GITHUB_LABELS;

  viewState$?: Observable<{ isDisabled: boolean; hasToken: boolean }>;

  constructor(
    private accessTokenService: AccessTokenService,
    private appStateService: AppFormStateService,
    private providersAuthService: ProvidersAuthService,
    private formService: CodeRepositoryFormService,
    @Inject('feature')
    private featureFields: ClientModel.AppFeatureFields
  ) {}

  authorize(): void {
    this.providersAuthService.authorize(
      this.configuration,
      environment.github.clientId,
      { scope: 'api' }
    );
  }

  ngOnInit(): void {
    this.formService.useFeature(this.featureFields);

    this.viewState$ = combineLatest([
      this.appStateService.disabled$,
      this.accessTokenService.get(GITHUB_CONFIG.key).pipe(map(Boolean)),
    ]).pipe(
      map(([isDisabled, hasToken]) => ({ isDisabled, hasToken })),
      tap(({ isDisabled, hasToken }) => {
        !isDisabled && hasToken
          ? this.formService.enable()
          : this.formService.disable();
      })
    );
  }
}
