import { HttpResponse } from '@angular/common/http';
import { FesNotification } from '../../../../brokers/fes-notification.model';
import { ApiModel } from '../utils/json-api';

export const appCreateSuccess = (
  response: HttpResponse<ApiModel.Apps>
): FesNotification =>
  new FesNotification(
    `${
      (response.body?.data as ApiModel.App).attributes.appName
    } app created successfully.`
  );
