import { Color } from '../../color.model';

export const amber: Color = {
  light: {
    0: 'hsl(0, 0%, 0%)',
    1: 'hsl(39, 70.0%, 99.0%)',
    2: 'hsl(40, 100%, 96.5%)',
    3: 'hsl(44, 100%, 91.7%)',
    4: 'hsl(43, 100%, 86.8%)',
    5: 'hsl(42, 100%, 81.8%)',
    6: 'hsl(38, 99.7%, 76.3%)',
    7: 'hsl(36, 86.1%, 67.1%)',
    8: 'hsl(35, 85.2%, 55.1%)',
    9: 'hsl(39, 100%, 57.0%)',
    10: 'hsl(35, 100%, 55.5%)',
    11: 'hsl(30, 100%, 34.0%)',
    12: 'hsl(20, 80.0%, 17.0%)',
  },
  dark: {
    0: 'hsl(0, 0%, 0%)',
    1: 'hsl(36, 100%, 6.1%)',
    2: 'hsl(35, 100%, 7.6%)',
    3: 'hsl(32, 100%, 10.2%)',
    4: 'hsl(32, 100%, 12.4%)',
    5: 'hsl(33, 100%, 14.6%)',
    6: 'hsl(35, 100%, 17.1%)',
    7: 'hsl(35, 91.0%, 21.6%)',
    8: 'hsl(36, 100%, 25.5%)',
    9: 'hsl(39, 100%, 57.0%)',
    10: 'hsl(43, 100%, 64.0%)',
    11: 'hsl(39, 90.0%, 49.8%)',
    12: 'hsl(39, 97.0%, 93.2%)',
  },
};
