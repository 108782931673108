import { ButtonColor, ButtonType } from '@spryker-oryx/ui/button';

export enum DefaultButtonType {
  Solid = ButtonType.Solid,
  Outline = ButtonType.Outline,
}

export enum DefaultButtonColor {
  Primary = ButtonColor.Primary,
  Error = ButtonColor.Error,
  Neutral = ButtonColor.Neutral,
}
