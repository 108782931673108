import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AccessTokenService } from '../auth/access-token.service';
import { canRenew } from '../auth/access-token.util';
import { AUTH_KEY } from '../auth/auth.config';
import { ClientModel } from '../auth/auth.model';
import { NO_ACCESS_ROUTE } from '../constants';
import { PROVIDERS_CONFIG } from '../modules/launchpad/features/code-repository/code-repository-providers.config';
import { CodeRepositoryConfig } from '../modules/launchpad/features/code-repository/model';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private readonly accessTokenService: AccessTokenService,
    private router: Router
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleResponseError(error, request, next);
      })
    );
  }

  private handleResponseError(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (error.status === 401) {
      const KEY = this.getTokenKeyForHandle(request);

      return this.accessTokenService.get(KEY).pipe(
        take(1),
        switchMap((token: ClientModel.AccessToken | null) => {
          if (token && canRenew(token)) {
            return this.accessTokenService
              .renew(token as Required<ClientModel.AccessToken>, KEY)
              .pipe(take(1));
          } else {
            return throwError(() => error);
          }
        }),
        switchMap((token) => {
          let paramReq: HttpRequest<unknown> = request.clone();

          if (token) {
            paramReq = request.clone({
              setHeaders: {
                Authorization: `${token.tokenType} ${token.accessToken}`,
              },
            });
          }

          return next.handle(paramReq);
        }),
        catchError((error: HttpErrorResponse) => {
          this.accessTokenService.remove(KEY);

          if (KEY === AUTH_KEY) {
            this.router.navigateByUrl(NO_ACCESS_ROUTE);
          }

          return throwError(() => error);
        })
      );
    }

    return throwError(() => error);
  }

  private getTokenKeyForHandle(
    request: HttpRequest<unknown>
  ): typeof AUTH_KEY | CodeRepositoryConfig['key'] {
    const configs = Object.values(PROVIDERS_CONFIG);
    const configWithSameApi = configs.find((config) =>
      request.url.includes(config.baseApi)
    );
    return configWithSameApi?.key ?? AUTH_KEY;
  }
}
