import { Color } from '../../color.model';

export const slate: Color = {
  light: {
    1: 'hsl(206, 30.0%, 98.8%)',
    2: 'hsl(210, 16.7%, 97.6%)',
    3: 'hsl(209, 13.3%, 95.3%)',
    4: 'hsl(209, 12.2%, 93.2%)',
    5: 'hsl(208, 11.7%, 91.1%)',
    6: 'hsl(208, 11.3%, 88.9%)',
    7: 'hsl(207, 11.1%, 85.9%)',
    8: 'hsl(205, 10.7%, 78.0%)',
    9: 'hsl(206, 6.0%, 56.1%)',
    10: 'hsl(206, 5.8%, 52.3%)',
    11: 'hsl(206, 6.0%, 43.5%)',
    12: 'hsl(206, 24.0%, 9.0%)',
  },
  dark: {
    1: 'hsl(200, 7.0%, 8.8%)',
    2: 'hsl(195, 7.1%, 11.0%)',
    3: 'hsl(197, 6.8%, 13.6%)',
    4: 'hsl(198, 6.6%, 15.8%)',
    5: 'hsl(199, 6.4%, 17.9%)',
    6: 'hsl(201, 6.2%, 20.5%)',
    7: 'hsl(203, 6.0%, 24.3%)',
    8: 'hsl(207, 5.6%, 31.6%)',
    9: 'hsl(206, 6.0%, 43.9%)',
    10: 'hsl(206, 5.2%, 49.5%)',
    11: 'hsl(206, 6.0%, 63.0%)',
    12: 'hsl(210, 6.0%, 93.0%)',
  },
};
