export enum AppEditTitles {
  confirmTitle = 'Discard unsaved changes?',
  confirmOk = 'Discard',
  confirmCancel = 'Cancel',
}

export enum AppEditError {
  fetch = 'Error: It could not load the data for the selected application.',
  updateSuffix = 'app could not be saved.',
}

export enum AppEditSuccess {
  title = 'Changes saved successfully.',
  description = 'In order for the changes to become effective, application needs to be updated.',
}
