import { launchpadUiFeature } from '@spryker-intenal/launchpad-ui';
import { AppFeature, coreFeature } from '@spryker-oryx/core';
import { I18nFeature } from '@spryker-oryx/i18n';
import { fontawesomeLink } from '@spryker-oryx/resources';
import { siteFeature } from '@spryker-oryx/site';
import {
  linkComponent,
  notificationCenterComponent,
  optionComponent,
  uiFeature,
} from '@spryker-oryx/ui';

export const launchpadFeatures: AppFeature[] = [
  launchpadUiFeature,
  {
    options: {
      components: {
        root: 'body',
      },
    },
    resources: {
      graphics: {
        logo: {
          source: () => import('../assets/logo').then((m) => m.logo),
        },
      },
      fonts: fontawesomeLink,
    },
  },
  {
    ...uiFeature,
    components: [
      ...(uiFeature.components ?? []),
      // TODO: should be fixed, these components are not working if they are not preloaded
      optionComponent({
        impl: {
          load: optionComponent().impl,
        },
      }),
      notificationCenterComponent({
        impl: {
          load: notificationCenterComponent().impl,
        },
      }),
      linkComponent({
        stylesheets: [
          ...(linkComponent().stylesheets ?? []),
          {
            theme: 'fes',
            rules: () =>
              import('@spryker-oryx/ui/link').then(
                (m) => m.storefrontLinkStyles
              ),
          },
        ],
      }),
    ],
  },
  new I18nFeature(),
  coreFeature,
  siteFeature,
];
