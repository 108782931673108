import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationService } from '@spryker-oryx/ui/notification-center';
import {
  FES_ERROR_MAP_TOKEN,
  FES_SUCCESS_MAP_TOKEN,
} from './brokers/fes-notification-config-map.token';
import {
  FES_NOTIFICATION_ERROR_MAP_TOKEN,
  FES_NOTIFICATION_SUCCESS_MAP_TOKEN,
} from './brokers/fes-notification.config';
import { AtrsRequestIdentifier } from './brokers/request-identifier/atrs-request-identifier.service';
import { GlueRequestIdentifier } from './brokers/request-identifier/glue-request-identifier.service';
import { REQUEST_IDENTIFIER_STRATEGIES_TOKEN } from './brokers/request-identifier/request-identifier.model';
import { ErrorHandlingInterceptor } from './interceptors/error-handling.interceptor';
import { FesAuthInterceptor } from './interceptors/fes-auth.interceptor';
import { ProvidersAuthInterceptor } from './modules/launchpad/features/code-repository/providers-auth.interceptor';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';

@NgModule({
  declarations: [RootComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    RootRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FesAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProvidersAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    {
      provide: FES_ERROR_MAP_TOKEN,
      useValue: FES_NOTIFICATION_ERROR_MAP_TOKEN,
    },
    {
      provide: FES_SUCCESS_MAP_TOKEN,
      useValue: FES_NOTIFICATION_SUCCESS_MAP_TOKEN,
    },
    {
      provide: REQUEST_IDENTIFIER_STRATEGIES_TOKEN,
      useValue: [new AtrsRequestIdentifier(), new GlueRequestIdentifier()],
    },
    NotificationService,
  ],
  bootstrap: [RootComponent],
})
export class RootModule {}
