import { FormControl } from '@angular/forms';
import { FesNotification } from '../../brokers/fes-notification.model';

export const defaultHostingProviderKey = 'hostingProvider';
export const defaultHostingProviderValue = 'spryker-demo';
export const defaultHostingProviderDescription = 'Spryker demo hosting';

export interface HostingProvidersResponse {
  data: HostingProviders | HostingProviders[];
  links?: Record<string, string>;
}

export interface HostingProvidersTypesResponse {
  data: HostingProvidersTypes | HostingProvidersTypes[];
  links?: Record<string, string>;
}

export interface HostingProviders {
  type: 'hosting-providers';
  id: string;
  attributes: {
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  links: {
    self: string;
  };
}

export interface HostingProvidersTypes {
  type: 'hosting-providers-types';
  id: string;
  attributes: {
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  links: {
    self: string;
  };
}

export interface SubscriptionFormData {
  name: FormControl<string | null>;
  hostingProvider: FormControl<string | null>;
  personalToken: FormControl<string | null>;
}

export enum SubscriptionFormControls {
  Name = 'name',
  HostingProvider = 'hostingProvider',
  PersonalToken = 'personalToken',
}

export enum HostingRemoveTitles {
  title = 'Remove Subscription',
  removeTitle = 'You are about to remove:',
  removeDescription = 'You will no longer be able to assign this subscription to any applications. Please note that only subscriptions that are not assigned to any applications can be removed.',
  cancelButton = 'Cancel',
  removeButton = 'Remove subscription',
}

enum HostingProviderDeleteTitles {
  error = 'Subscription can not be deleted. Please unassign this subscription from all apps first.',
  success = 'Subscription was removed successfully.',
}

export const hostingProviderDeleteError = (): FesNotification =>
  new FesNotification(HostingProviderDeleteTitles.error);

export const hostingProviderDeleteSuccess = (): FesNotification =>
  new FesNotification(HostingProviderDeleteTitles.success);
