import { Injectable } from '@angular/core';
import { WindowService } from '../../../../services/window/window.service';
import { CodeRepositoryConfig } from './model';

@Injectable({ providedIn: 'root' })
export class ProvidersAuthService {
  constructor(private windowService: WindowService) {}

  authorize(
    config: Partial<CodeRepositoryConfig>,
    clientId: string,
    extraParams: Record<string, string> = {}
  ): void {
    const url = `${config.authorizeUrl}?client_id=${clientId}&redirect_uri=${
      config.callback
    }&response_type=code${this.addExtra(extraParams)}`;

    this.windowService.open(url, '_blank', config.windowOptions);
  }

  private addExtra(config: Record<string, string>): string {
    return Object.entries(config).reduce(
      (acc, [key, value]) => `${acc}&${key}=${value}`,
      ''
    );
  }
}
