import { Color } from '../../color.model';

export const black: Color = {
  light: {
    1: 'hsla(0, 0%, 0%, 0.012)',
    2: 'hsla(0, 0%, 0%, 0.027)',
    3: 'hsla(0, 0%, 0%, 0.047)',
    4: 'hsla(0, 0%, 0%, 0.071)',
    5: 'hsla(0, 0%, 0%, 0.090)',
    6: 'hsla(0, 0%, 0%, 0.114)',
    7: 'hsla(0, 0%, 0%, 0.141)',
    8: 'hsla(0, 0%, 0%, 0.220)',
    9: 'hsla(0, 0%, 0%, 0.439)',
    10: 'hsla(0, 0%, 0%, 0.478)',
    11: 'hsla(0, 0%, 0%, 0.565)',
    12: 'hsla(0, 0%, 0%, 0.910)',
  },
  dark: {
    1: 'hsla(0, 0%, 100%, 0)',
    2: 'hsla(0, 0%, 100%, 0.013)',
    3: 'hsla(0, 0%, 100%, 0.034)',
    4: 'hsla(0, 0%, 100%, 0.056)',
    5: 'hsla(0, 0%, 100%, 0.086)',
    6: 'hsla(0, 0%, 100%, 0.124)',
    7: 'hsla(0, 0%, 100%, 0.176)',
    8: 'hsla(0, 0%, 100%, 0.249)',
    9: 'hsla(0, 0%, 100%, 0.386)',
    10: 'hsla(0, 0%, 100%, 0.446)',
    11: 'hsla(0, 0%, 100%, 0.592)',
    12: 'hsla(0, 0%, 100%, 0.923)',
  },
};
