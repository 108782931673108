import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestIdentifierStrategy } from './request-identifier.model';

const { glueBaseApi } = environment;

@Injectable()
export class GlueRequestIdentifier implements RequestIdentifierStrategy {
  canHandle(request: HttpRequest<unknown>): boolean {
    return request.url.indexOf(glueBaseApi) > -1;
  }

  getIdentifier(request: HttpRequest<unknown>): string {
    const apps = '/apps';
    const hostingProviders = '/hosting-providers';
    const identifier = request.url.substring(
      request.url.indexOf(glueBaseApi) + glueBaseApi.length,
      request.url.indexOf('?') > 0 ? request.url.indexOf('?') : undefined
    );

    if (identifier.indexOf(apps) >= 0) {
      return this.getIdentifierFromUrl(request, identifier, apps);
    }

    if (identifier.indexOf(hostingProviders) >= 0) {
      return this.getIdentifierFromUrl(request, identifier, hostingProviders);
    }

    return `${request.method}${identifier}`;
  }

  private getIdentifierFromUrl(
    request: HttpRequest<unknown>,
    identifier: string,
    endpoint: string
  ): string {
    if (identifier.indexOf('/events') >= 0) {
      return `${request.method}${endpoint}/events`;
    }

    if (
      identifier.substring(identifier.indexOf(endpoint) + endpoint.length)
        .length > 0
    ) {
      return `${request.method}${endpoint}/id`;
    }

    return `${request.method}${identifier}`;
  }
}
