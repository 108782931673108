import { Component, Input } from '@angular/core';
import { IconTypes } from '@spryker-oryx/ui/icon';
import { Subscription, tap } from 'rxjs';
import { WindowService } from '../../../../../services/window/window.service';
import { CodeRepositoryFormService } from '../code-repository-form.service';

@Component({
  selector: 'pad-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss'],
})
export class InstallComponent {
  @Input() label!: string;
  @Input() installUrl!: string;
  @Input() target!: string;
  @Input() windowOptions!: string;
  @Input() callback!: string;

  private windowFocusSubscription!: Subscription;
  linkIcon = IconTypes.Link;

  constructor(
    private windowService: WindowService,
    private formService: CodeRepositoryFormService
  ) {}

  private resetOrganization(): void {
    const controls = this.formService.controls;
    controls.organizationId.setValue('');
    controls.organizationName.reset();
  }

  install(): void {
    const url = `${this.installUrl}?redirect_uri=${this.callback}`;
    const child = this.windowService.open(url, this.target, this.windowOptions);

    if (child) {
      this.windowFocusSubscription = this.windowService
        .whenWindowFocus()
        .pipe(tap(() => this.resetOrganization()))
        .subscribe(() => {
          if (child.closed) {
            this.windowFocusSubscription.unsubscribe();
          }
        });
    }
  }
}
