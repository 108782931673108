import { Color } from '../../color.model';

export const yellow: Color = {
  light: {
    0: 'hsl(0, 0%, 0%)',
    1: 'hsl(60, 54.0%, 98.5%)',
    2: 'hsl(52, 100%, 95.5%)',
    3: 'hsl(55, 100%, 90.9%)',
    4: 'hsl(54, 100%, 86.6%)',
    5: 'hsl(52, 97.9%, 82.0%)',
    6: 'hsl(50, 89.4%, 76.1%)',
    7: 'hsl(47, 80.4%, 68.0%)',
    8: 'hsl(48, 100%, 46.1%)',
    9: 'hsl(53, 92.0%, 50.0%)',
    10: 'hsl(50, 100%, 48.5%)',
    11: 'hsl(42, 100%, 29.0%)',
    12: 'hsl(40, 55.0%, 13.5%)',
  },
  dark: {
    0: 'hsl(0, 0%, 0%)',
    1: 'hsl(45, 100%, 5.5%)',
    2: 'hsl(46, 100%, 6.7%)',
    3: 'hsl(45, 100%, 8.7%)',
    4: 'hsl(45, 100%, 10.4%)',
    5: 'hsl(47, 100%, 12.1%)',
    6: 'hsl(49, 100%, 14.3%)',
    7: 'hsl(49, 90.3%, 18.4%)',
    8: 'hsl(50, 100%, 22.0%)',
    9: 'hsl(53, 92.0%, 50.0%)',
    10: 'hsl(54, 100%, 68.0%)',
    11: 'hsl(48, 100%, 47.0%)',
    12: 'hsl(53, 100%, 91.0%)',
  },
};
