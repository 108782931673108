import {
  HttpErrorResponse,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { FesNotification } from '../../../../../brokers/fes-notification.model';
import { HostingApiModel } from '../../../app/models/app-process.model';
import {
  HostingEventsError,
  HostingEventsSuccess,
} from './hosting-events.titles';

export function hostingEventSuccess(
  response: HttpResponse<unknown>,
  request: HttpRequest<HostingApiModel.EventDataPayload>
): FesNotification {
  return new FesNotification(
    HostingEventsSuccess[
      (
        request.body as HostingApiModel.EventDataPayload
      ).data.attributes.eventName
    ]
  );
}

export function hostingEventError(
  error: HttpErrorResponse,
  request: HttpRequest<HostingApiModel.EventDataPayload>
): FesNotification {
  return new FesNotification(
    HostingEventsError[
      (
        request.body as HostingApiModel.EventDataPayload
      ).data.attributes.eventName
    ]
  );
}
