import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { DEFAULT_INPUT_ERROR_MESSAGES } from './handle-input-error.titles';

@Pipe({
  name: 'handleInputError',
})
export class HandleInputErrorPipe implements PipeTransform {
  transform(
    errors: ValidationErrors,
    messages: Record<string, string>
  ): string | null {
    if (errors) {
      const firstMatchingKey = Object.keys(errors as ValidationErrors).find(
        (errorKey) => errorKey in messages
      );

      return firstMatchingKey
        ? messages[firstMatchingKey]
        : DEFAULT_INPUT_ERROR_MESSAGES.default;
    } else {
      return null;
    }
  }
}
