import { Pipe, PipeTransform } from '@angular/core';
import {
  LoaderState,
  LoaderStateStatus,
} from '../../utils/models/loading.model';

@Pipe({
  name: 'loadingStatusIs',
})
export class LoadingStatusPipe implements PipeTransform {
  transform(
    loader: LoaderState<any>,
    expectedStatus: keyof typeof LoaderStateStatus
  ): boolean {
    if (!loader) return false;
    const status = LoaderStateStatus[expectedStatus];
    if (!status) {
      throw TypeError(
        'expectedStatus is not valid. Should be "LOADING" | "SUCCESS" | "ERROR".'
      );
    }
    return loader.status === status;
  }
}
