import { Injectable } from '@angular/core';
import { filter, fromEvent, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WindowService {
  open(url: string, target = '_blank', options?: string): Window | null {
    return window.open(url, target, options);
  }

  /**
   * Make sure `window.close` is async.
   *
   * Some browsers (Safari) will do this operation synchronously.
   */
  close(): void {
    setTimeout(() => {
      window.close();
    }, 1);
  }

  collectPostMessage<T extends { type: string }>(
    type: string
  ): Observable<MessageEvent<T>> {
    return fromEvent<MessageEvent<T>>(window, 'message').pipe(
      filter((e) => e.data.type === type)
    );
  }

  get location(): Partial<Location> {
    return window.location;
  }

  whenWindowFocus(): Observable<Event> {
    return fromEvent(window, 'focus');
  }
}
